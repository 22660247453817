export class UtilityService {
	constructor($timeout, $window, DEFAULT_IMG, $log, $location, $anchorScroll) {
		'ngInject';
		this.timeout = $timeout;
		this.log = $log;
		this.window = $window;
		this.location = $location;
		this.anchorScroll = $anchorScroll;
		this.defaultImg = DEFAULT_IMG;
	}

	focus(id) {
		this.timeout(() => {
			const element = this.window.document.getElementById(id);
			if (element) {
				element.focus();
			}
		}, 100);
	}

	causeError(argument) {
		foo();
		function foo() {
			bar();
		}
		function bar() {
			// eslint-disable-next-line
			var x = y; // NOTE: "y" is undefined.
		}
		return argument;
	}

	calculateMaxHeight(className) {
		const elemList = this.window.document.getElementsByClassName(className);
		var maxHeight = 0;
		for (let i = 0; i < elemList.length; i++) {
			if (elemList[i].clientHeight > maxHeight) {
				maxHeight = elemList[i].clientHeight;
			}
		}
		return {height: `${maxHeight}px`};
	}

	/**
	 * @deprecated
	 * Parse number if type is string
	 * @param {string} value
	 * @returns number
	 */
	parseIntIfString(value) {
		return typeof value === 'string' ? parseInt(value) : value;
	}

	// TODO: remove all instances and refactor
	/**
	 * @deprecated
	 * @param {string} value
	 * @returns number
	 */
	parseFloatIfString(value) {
		return value && typeof value === 'string' ? parseFloat(value) : value;
	}

	/**
	 * Calculate the biggest element size in DOM by its class name
	 * @param {string} side
	 * @param {string} className
	 * @returns int
	 */
	maxElm(side, className) {
		const _sideMethod = side === 'width' ? 'scrollWidth' : 'scrollHeight';
		const _elemList = this.window.document.getElementsByClassName(className);
		let _max = 0;
		for (let i = 0; i < _elemList.length; i++) {
			if (_elemList[i][_sideMethod] > _max) {
				_max = _elemList[i][_sideMethod];
			}
		}
		return _max;
	}

	/**
	 * Generate seudo unique id
	 * @returns string
	 */
	uuid() {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}
		return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
	}

	/**
	 * Generate a new url string
	 * @param {string} origUrl
	 * @param {int} wid
	 * @param {int} hei
	 * @param {boolean} fmt
	 * @returns url string
	 */
	generateImageVersion(origUrl, wid, hei, fmt) {
		const _urlValue = origUrl || this.defaultImg;
		let _url = _urlValue.replace(/wid=(\d+)/, 'wid=' + wid).replace(/hei=(\d+)/, 'hei=' + hei);
		if (fmt === true) {
			_url = _url.replace(/&defaultImage/, '&fmt=png-alpha&defaultImage');
		}
		return _url;
	}

	getRootDomain() {
		var parts = this.window.document.domain.split('.');
		if (parts.length > 2) {
			parts.shift();
		}
		return parts.join('.');
	}

	areCookiesEnabled() {
		let cookieEnabled = navigator.cookieEnabled;
		if (!cookieEnabled) {
			this.window.document.cookie = 'testCookie';
			cookieEnabled = this.window.document.cookie.indexOf('testCookie') !== -1;
		}
		return cookieEnabled;
	}

	/**
	 * Return unit type based on system type
	 * @param {string} systemName
	 * @returns String
	 */
	systemTypeUnit(systemName) {
		const systemTypesUnits = [
			{
				systems: ['furnace_with_evaporator_coil', 'gas_boiler', 'gas_furnace'],
				unit: 'BTU',
			},
			{
				systems: ['water_heater'],
				unit: 'Gal',
			},
			{
				systems: [
					'ductless',
					'evaporator_coil',
					'fan_coil',
					'heat_pump_with_evaporator_coil',
					'heat_pump_without_air_handler',
					'heat_pump',
					'hybrid_heat_pump',
					'packaged_unit',
					'straight_cool_existing_furnace',
					'straight_cool_plus_furnace_only',
					'straight_cool_plus_furnace',
					'straight_cool_without_air_handler',
					'straight_cool',
					'single_zone_ductless',
				],
				unit: 'Ton',
			},
		];

		const groupOfSystemTypes = systemTypesUnits.filter(group => group.systems.indexOf(systemName) !== -1);

		const unit = groupOfSystemTypes.length > 0 ? groupOfSystemTypes[0].unit : false;

		if (!unit) {
			this.log.warn('Not unit type available for System type ' + systemName + '.');
		}
		return unit;
	}

	/**
	 * Return a matching object by attribute - value or first item in collection
	 * @param {Array} collection
	 * @param {any} attrValue
	 * @param {string} attr
	 * @param {Boolean} fallbackValue
	 * @returns {Object} Object or false
	 */
	getObjectInArrayByAttr(collection, attrValue, attr, fallbackValue) {
		if (collection instanceof Array && collection.length > 0 && typeof attr === 'string') {
			const _searchResults = this.searchInCollection(collection, attrValue, attr, fallbackValue);
			if (_searchResults.res) {
				return _searchResults.res;
			} else if (_searchResults.fall) {
				_searchResults.fall['isFallback'] = true;
				return _searchResults.fall;
			} else {
				return collection[0];
			}
		} else {
			this.log.error(`wrong argument(s) collection: ${JSON.stringify(collection)} || attr: ${attr}`);
			return false;
		}
	}

	/**
	 * Search object in collection with an attribute with value
	 * @param {Array} arr
	 * @param {string} attr
	 * @param {any} val
	 * @returns Number
	 */
	getObjIdxByAttrValue(collection, attr, val) {
		if (collection instanceof Array && typeof attr === 'string') {
			let objIdx;
			for (let i = 0; i < collection.length; i += 1) {
				if (collection[i][attr] === val) {
					objIdx = i;
					break;
				}
			}
			return angular.isUndefined(objIdx) ? -1 : objIdx;
		} else {
			this.log.error(`wrong argument(s) arr: ${collection} || attr: ${attr}`);
			return false;
		}
	}

	/**
	 * @param {Array} totalItems
	 * @returns {Int}
	 */
	columnFactor(totalItems) {
		const baseColWidth = 4;
		const total = totalItems.length;
		return total < baseColWidth || total === 6 ? 3 : baseColWidth;
	}

	/**
	 * Test if a json can be deserialized
	 * @param {string} str
	 * @returns boolean
	 */
	isValidJson(str) {
		try {
			angular.fromJson(str);
		} catch (e) {
			this.log.warn(e);
			return false;
		}
		return true;
	}

	/**
	 * Helper, search item in collection
	 * @param {*} collection
	 * @param {*} attrValue
	 * @param {*} attr
	 * @param {*} fallback
	 * @returns {Object} Matching object by a property value
	 */
	searchInCollection(collection, attrValue, attrName, fallback) {
		var result = {res: null, fall: null};
		for (let i = 0; i < collection.length; i++) {
			if (collection[i][attrName] === attrValue) {
				result.res = collection[i];
			}
			if (fallback) {
				if (collection[i][attrName] === fallback) {
					result.fall = collection[i];
				}
			}
		}
		return result;
	}

	findIndexByValueInKey(collection, key, value) {
		let idx = null;
		for (let i = 0; i < collection.length; i++) {
			if (collection[i][key] === value) {
				idx = i;
				break;
			}
		}
		return idx;
	}

	/**
	 * Validate if object keys exist in array of keys.
	 * @param {Array[string]} keys
	 * @param {Object} obj
	 * @returns {Boolean}
	 */
	validateKeysInObject(keys, obj) {
		let _msg = `The alternateProductModal component require keys: `;
		const _isValid = Object.keys(obj).reduce((acc, curr) => {
			if (keys.indexOf(curr) === -1) {
				_msg += `${curr} `;
				return false;
			}
			return acc;
		}, true);
		if (!_isValid) this.log.error(_msg);
		return _isValid;
	}

	/**
	 * Validate if object keys exist in array of keys.
	 * Deprecated
	 * @param {Array[string]} keys
	 * @param {string} attr
	 * @param {String|number} val
	 * @returns {Object|undefined}
	 */
	getObjByAttrValue(arr, attr, val) {
		let _obj;
		for (let i = 0; i < arr.length; i++) {
			if (arr[i][attr] === val) {
				_obj = arr[i];
				break;
			}
		}
		return _obj;
	}

	/**
	 * gotoAnchor Scroll to anchor '#anchor'
	 * @param {String} id name if id to scroll to
	 * @returns {Void}
	 */
	gotoAnchor(id) {
		if (!id) this.log.warn('Element id is required to scroll to');
		this.timeout(() => {
			this.location.hash(id);
			this.anchorScroll();
		}, 500);
	}

	makeArrayOfAttrValues(collection, attr) {
		const newArray = [];
		angular.forEach(collection, item => {
			newArray.push(item[attr]);
		});
		return newArray;
	}

	/**
	 * Get unique objects
	 * @param {Array[any]} collection
	 * @returns <Array[any]>
	 */
	getUniqueObjects(collection) {
		return collection.filter(
			(item, index) => index === collection.findIndex(obj => JSON.stringify(obj) === JSON.stringify(item))
		);
	}
}
//
let compMod = angular.module('mod.svc.UtilityService', []).service('UtilityService', UtilityService);
export default compMod = compMod.name;
