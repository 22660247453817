// #region dependencies
import './app-shell.scss';
import AppShellTpl from './app-shell.html';
// #endregion
const compConfig = {
	template: AppShellTpl,
	controller: AppShellController,
};
/** @ngInject */
function AppShellController(
	$scope,
	$rootScope,
	$q,
	$log,
	$window,
	ENV,
	HOST_URL,
	G_TAG_URL,
	G_TAG_ID,
	API,
	$state,
	NavService,
	IntercomService,
	MetricsService,
	AuthService,
	DOMHelpersSvc
) {
	const $ctrl = this;
	const GTM_ELM_ID = 'gtmScript';
	const IC_ELM_ID = 'icScript';
	$ctrl.$onInit = () => {
		$ctrl.appHost = HOST_URL;
		$ctrl.apiHost = API;
		$ctrl.pageTitle = $rootScope.pageTitle;
		$ctrl.appShellLoaded = false;
		$ctrl.isSplashVisible = true;
	};
	$scope.$on('$viewContentLoaded', () => {
		loadGGLE();
		loadICOM();
	});

	function loadGGLE() {
		if (!DOMHelpersSvc.getById(GTM_ELM_ID)) {
			loadGTM(GTM_ELM_ID)
				.then(res => {
					// eslint-disable-next-line angular/window-service
					window.dataLayer = window.dataLayer || [];
					function gtag() {
						// eslint-disable-next-line no-undef
						dataLayer.push(arguments);
					}
					gtag('js', new Date());
					gtag('config', G_TAG_ID);
					MetricsService.init();
					logOnLoadEvent(GTM_ELM_ID, res);
				})
				.catch(handle_error.bind(null, GTM_ELM_ID));
		}
	}

	function loadICOM() {
		if (
			ENV !== 'development' &&
			$state?.current?.name &&
			!NavService.isStatePublic($state.current.name) &&
			!DOMHelpersSvc.getById(IC_ELM_ID)
		) {
			loadIntercom(IC_ELM_ID)
				.then(res => {
					logOnLoadEvent(IC_ELM_ID, res);
					const _user = AuthService.currentUser();
					if (_user) IntercomService.boot(_user);
				})
				.catch(handle_error.bind(null, IC_ELM_ID));
		}
	}

	// todo: move function to a n utility service
	function stringifyEvent(e) {
		const obj = {};
		Object.keys(e).forEach(k => {
			obj[k] = e[k];
		});

		return JSON.stringify(
			obj,
			(_, v) => {
				if (v instanceof Node) return 'Node';
				if (v instanceof Window) return 'Window';
				return v;
			},
			' '
		);
	}

	// intercom
	function loadIntercom(elmId) {
		const _start = Date.now();
		const _deferred = $q.defer();
		const APP_ID = 'wmg4i4pq';
		$window.intercomSettings = {app_id: APP_ID};
		const ic = $window.Intercom;
		if (typeof ic === 'function') {
			ic('reattach_activator');
			ic('update', $window.intercomSettings);
			_deferred.resolve('icScript: already loaded');
		} else {
			const i = function () {
				i.c(arguments);
			};
			i.q = [];
			i.c = function (args) {
				i.q.push(args);
			};
			$window.Intercom = i;
			const src = `https://widget.intercom.io/widget/${APP_ID};`;

			DOMHelpersSvc.insertScriptHead(src, elmId)
				.then(() => _deferred.resolve({_start}))
				.catch(err => _deferred.reject(err));
		}
		return _deferred.promise;
	}

	// gtm
	function loadGTM(elmId) {
		const _deferred = $q.defer();
		const _start = Date.now();
		const src = `${G_TAG_URL}?id=${G_TAG_ID}`;

		DOMHelpersSvc.insertScriptHead(src, elmId)
			.then(() => _deferred.resolve({_start}))
			.catch(err => _deferred.reject(err));
		return _deferred.promise;
	}

	function logOnLoadEvent(elementId, res) {
		$log.info(`element with ID: ${elementId} was loaded in: ${res?._start ? Date.now() - res._start : 0} ms`);
	}

	function handle_error(elementId, err) {
		$log.error(`fail to load element with ID: ${elementId}, with error: ${stringifyEvent(err)}`);
	}
}

let compMod = angular.module('mod.cmp.appShell', []).component('appShell', compConfig);
export default compMod = compMod.name;
