const publicStates = [
	'view.consultation',
	'view.proposal',
	'view.confirmation',
	'view.accepted',
	'view.inspector',
	'view.lost',
	'view.expired',
	'view.unavailable',
	'user.auth',
	'user.logout',
	'user.login',
	'user.forgotPass',
	'user.setPassword',
	'user.info',
	// legacy
	'customerdetails',
	'customeraccepted',
];

export default publicStates;
