import themedStates from '@Constants/states-themed.constant.js';
//
const stateChangeStart = $injector => {
	const $rootScope = $injector.get('$rootScope');
	const changeStart = $rootScope.$on('$stateChangeStart', (e, toState) => {
		// inject dependencies
		const NavService = $injector.get('NavService');
		const $state = $injector.get('$state');
		const $log = $injector.get('$log');
		const AuthService = $injector.get('AuthService');
		const UtilityService = $injector.get('UtilityService');
		const $uibModalStack = $injector.get('$uibModalStack');
		const user = AuthService.currentUser();

		// close any open modal on transition
		$uibModalStack.dismissAll();

		// Is transitioning to a public page?
		const ocaFlowState = 'user.login';
		const _isStatePublic = NavService.isStatePublic(toState.name);

		// if cookies not active & is not first run & is a private page redirect to user login
		if (!UtilityService.areCookiesEnabled() && !$rootScope?.appAlreadyRun && !_isStatePublic) {
			e.preventDefault();
			const $window = $injector.get('$window');
			$rootScope.appAlreadyRun = true;
			$log.error(`User's browser has cookies disabled accessing "${$window.location.href}"`);
			$state.go(ocaFlowState, {}, {reload: true});
			return false;
		}

		// if is a public page don't check if user logged in
		if (!_isStatePublic && !AuthService.isLoggedIn()) {
			e.preventDefault();
			$state.go('user.auth', {}, {reload: true});
		}

		// if in 'preview.accepted' page compose page title
		if (toState.name === 'preview.accepted') {
			$rootScope.pageTitle = user ? `${user.service_provider_name}  Proposal` : 'OnCall Air';
		} else {
			// set page title based on state title property
			$rootScope.pageTitle = toState.title ? toState.title : 'OnCall Air';
		}

		//  todo: refactor implementation to css vars
		// apply or reset provider colors
		const ThemingService = $injector.get('ThemingService');
		if (isThemableState(toState.name) && user?.theme) {
			ThemingService.generateSkin({
				brandPrimary: user.theme.primary_color,
				brandSecondary: user.theme.secondary_color,
			});
		} else {
			ThemingService.resetSkin();
		}

		// check if the should be apply
		function isThemableState(stateName) {
			const _isThemable = themedStates.indexOf(stateName) !== -1;
			return _isThemable;
		}
	});
	$rootScope.$on('$destroy', changeStart);
};
stateChangeStart.$inject = ['$injector'];
export default stateChangeStart;
