const httpDefaults = $http => {
	$http.defaults.headers.common = {
		'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
	};
	// $http.defaults.headers.post = {
	// 	'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
	// };
};
httpDefaults.$inject = ['$http'];
export default httpDefaults;
