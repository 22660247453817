export class ServiceProvider {
	constructor($http, API, PUBLIC_KEY) {
		'ngInject';
		this.http = $http;
		this.gate = '/serviceProvider';
		this.API = API;
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	getAllServiceProviders(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/allServiceProviders`, {params, cache});
	}

	getProposalLabelsByProviderId(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/proposalLabelsByProviderId`, {params, cache});
	}

	updateProposalLabelsByProviderId(params) {
		return this.http.post(`${this.API}${this.gate}/updateProposalLabelsByProviderId`, params);
	}

	getServiceProviderDetails(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/serviceProviderDetails`, {params, cache});
	}

	getServiceProviderDetailsExcerpt(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.API}/v2${this.gate}/serviceProviderDetailsExcerpt`, {params, cache, headers});
	}

	getAllEconomicProfileSettingsByServiceProvider(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/allEconomicProfileSettingsByServiceProvider`, {params, cache});
	}

	getAllCommissionTiers(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/allCommissionTiers`, {params, cache});
	}

	getBusinessUnits(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/businessUnits`, {params, cache});
	}

	getAllEmailTemplatesByServiceProvider(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/allEmailTemplatesByServiceProvider`, {params, cache});
	}

	getAllEmailTemplateAttributes(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/allEmailTemplateAttributes`, {params, cache});
	}

	getFeatureflags(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/featureflags`, {params, cache});
	}

	getFeatureFlagsForCustomer(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.API}${this.gate}/featureFlagsForCustomer`, {params, cache, headers});
	}

	updateEmailTemplateAttributes(params) {
		return this.http.post(`${this.API}${this.gate}/updateEmailTemplateAttributes`, params);
	}

	getSeries(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/series`, {params, cache});
	}

	postUpdateSeriesPreferences(params) {
		return this.http.post(`${this.API}${this.gate}/updateSeriesPrefererences`, params);
	}

	updateEconomicProfileSettingsByServiceProvider(params) {
		return this.http.post(`${this.API}${this.gate}/updateEconomicProfileSettingsByServiceProvider`, params);
	}

	postCreateCommissionTier(params) {
		return this.http.post(`${this.API}${this.gate}/createCommissionTier`, params);
	}

	postUpdateCommissionTier(params) {
		return this.http.post(`${this.API}${this.gate}/updateCommissionTier`, params);
	}

	postDeleteCommissionTier(params) {
		return this.http.post(`${this.API}${this.gate}/deleteCommissionTier`, params);
	}

	postUpdateProvider(params) {
		return this.http.post(`${this.API}${this.gate}/update`, params);
	}

	getProviderConnector(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/providerCustomConnector`, {params, cache});
	}
}

let compMod = angular.module('mod.svc.ServiceProviderService', []).service('ServiceProviderService', ServiceProvider);
export default compMod = compMod.name;
