let compMod = angular
	.module('module.constants.env', [])
	.constant('API', 'https://api.oncallair.com')
	.constant('VERSION', 'version_1_179_2_OBCI-17_f39e8b2')
	.constant('ENV', 'production')
	.constant('HOST_URL', 'https://app.oncallair.com')
	.constant('PUBLIC_KEY', 'WVudHMiXSwkdBR0UiLCJ1c2VyX2lkIjoXNlciJdfQLf88Iegu7LC8t2lcH9r_o70yUuRSVPZlYXR1cmVzzLCJ1')
	.constant('G_MAPS_KEY', 'AIzaSyCAdBZsEX-lNRigZjwYF8MHEiN6WcP_LFE')
	.constant('G_TAG_ID', 'G-ZTQ5XT0EYV')
	.constant('G_TAG_URL', 'https://www.googletagmanager.com/gtag/js')
	.constant('C4C_EASY_LINK', 'https://6is5gkfg1f.execute-api.us-east-1.amazonaws.com/prod/authenticate/easy-link')
	.constant('C4C_URL', 'https://app.creditforcomfort.com')
	.constant('AWS_BUCKET_REGION', 'us-east-1')
	.constant('AWS_IDENTITY_POOL_ID', 'us-east-1:23cc3f1e-bcf0-4aef-920f-5265b68fc66f')
	.constant('AWS_FLEXJOB_BUCKET_NAME', 'job-images-prod')
	.constant('AWS_PUBLIC_NOTES_BUCKET_NAME', 'proposal-attachments')
	.constant('AWS_SURVEY_BUCKET_NAME', 'oca-attachments-prod')
	.constant('AWS_ACCESSORY_BUCKET_NAME', 'custom-accessory-prod')
	.constant('AWS_SERIES_BUCKET_NAME', 'oca-series-images')
	.constant('AWS_IMPORTER_BUCKET_NAME', 'oca-importer')
	.constant('AUTH0_CLIENT_ID', 'kl0YlXJTGqMnxIautfSTmBHAAkPjgVmn')
	.constant('AUTH0_DOMAIN', 'oca-prod.us.auth0.com');
export default compMod = compMod.name;
