import {
	OfficeCustomEquipmentListState,
	OfficeCustomEquipmentCreateRoute,
	OfficeCustomEquipmentUpdateRoute,
} from '@Containers/office-custom-equipment-page/office-custom-equipment.route.js';
//
const routesConfig = $injector => {
	const $locationProvider = $injector.get('$locationProvider');
	$locationProvider.html5Mode(true).hashPrefix('');

	// redirect to returned state object ({name, params}) or default state
	function manageRejectedPermission(rejectedPermission) {
		if (rejectedPermission && rejectedPermission?.state) {
			return rejectedPermission;
		}

		return {state: 'navigator'};
	}

	function managePublicRejectedPermission(rejectedPermission) {
		if (rejectedPermission && rejectedPermission?.state) {
			return rejectedPermission;
		}

		return {
			state: 'info',
			params: {
				data: {
					title: 'Sorry, something went wrong',
					message: 'Please try again or contact us',
				},
			},
		};
	}

	const $stateProvider = $injector.get('$stateProvider');
	$stateProvider
		// #region navigator
		.state('navigator', {
			url: '/navigator?status&assignee&search',
			title: 'Navigator',
			template: '<navigator-page flags-capabilities-access="$resolve.flagsCapabilitiesAccess" />',
			params: {
				status: null,
				assignee: null,
				search: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($log, $ocLazyLoad) =>
					import(
						/* webpackChunkName: "navigatorPageMod" */
						'./app/containers/navigator-page/navigator-page.component.js'
					)
						.then(res => $ocLazyLoad.load({name: res.default}))
						.catch(err => $log.error(err)),
				/** @ngInject */
				flagsCapabilitiesAccess: (RoutesResolveSvc, $state) =>
					RoutesResolveSvc.getFlagsCapabilitiesLogAccess({fromState: 'navigator'})
						.then(data => data)
						.catch(() => $state.go('user.auth')),
			},
		})
		// #endregion
		// #region navigator.details - v1
		.state('navigator.details', {
			url: '/details/:position/:eventLogId/:consultationId/:proposalId',
			title: 'Proposal Snapshot',
			template: '<render-proposal-snapshot/>',
			params: {
				eventLogId: null,
				position: null,
				consultationId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					$q.all([
						import(
							/* webpackChunkName: "eventLogsMod" */
							'./app/components/event-logs/render-proposal-snapshot/render-proposal-snapshot.component.js'
						),
						import(
							/* webpackChunkName: "proposalPageMod" */
							'./app/containers/proposal-page/proposal-page.component.js'
						),
					])
						.then(res => {
							res.forEach(mod => {
								$ocLazyLoad.load({name: mod.default});
							});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region proposal abstract
		.state('proposal', {
			url: '/proposal',
			abstract: true,
			template: '<proposal-abstract-page provider-flags="$resolve.providerFlags" />',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "proposalAbstractMod" */
						'./app/containers/proposal-abstract-page/proposal-abstract-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				getUserCapabilities: ($state, RoutesResolveSvc) =>
					RoutesResolveSvc.getUserCapabilities({fromState: 'proposal'})
						.then(data => data)
						.catch(() => $state.go('proposal')),
				/** @ngInject */
				providerFlags: ($state, RoutesResolveSvc) =>
					RoutesResolveSvc.getProviderFlags({fromState: 'proposal'})
						.then(data => data)
						.catch(() => $state.go('user.auth')),
				/** @ngInject */
				logAccess: ($state, RoutesResolveSvc) =>
					RoutesResolveSvc.postLastUserAccess({fromState: 'proposal'})
						.then(data => data)
						.catch(() => $state.go('user.auth')),
			},
		})
		// #endregion
		// #region proposal.chargify
		.state('proposal.chargify', {
			url: '/chargify',
			template: '<chargify-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "chargifyPageMod" */
						'./app/containers/chargify-page/chargify-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region proposal.customer
		.state('proposal.customer', {
			url: '/customer',
			title: 'Customer View',
			template: '<customer-page/>',
			data: {
				permissions: {
					only: ['isDraft'],
					redirectTo: manageRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "customerPageMod" */
						'./app/containers/customer-page/customer-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region proposal.survey
		.state('proposal.survey', {
			url: '/survey',
			title: 'Survey Questions',
			template: '<survey-page/>',
			data: {
				permissions: {
					only: ['isDraft'],
					redirectTo: manageRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($log, $ocLazyLoad) =>
					import(
						/* webpackChunkName: "surveyPageMod" */
						'./app/containers/survey-page/survey-page.component.js'
					)
						.then(res => $ocLazyLoad.load({name: res.default}))
						.catch(err => $log.error(err)),
			},
		})
		// #endregion
		// #region proposal.equipment
		.state('proposal.equipment', {
			url: '/equipment',
			title: 'Equipment',
			template: '<equipment-page/>',
			data: {
				permissions: {
					only: ['isDraft'],
					redirectTo: manageRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "equipmentPageMod" */
						'./app/containers/equipment-page/equipment-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region proposal.installation
		.state('proposal.installation', {
			url: '/installation',
			title: 'Installation Items',
			template: '<installation-page/>',
			data: {
				permissions: {
					only: ['isDraft'],
					redirectTo: manageRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "installationPageMod" */
						'./app/containers/installation-page/installation-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region proposal.accessories
		.state('proposal.accessories', {
			url: '/accessories',
			title: 'Accessories Items',
			template: '<accessories-page/>',
			data: {
				permissions: {
					only: ['isDraft'],
					redirectTo: manageRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "accessoriesPageMod" */
						'./app/containers/accessories-page/accessories-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region proposal.summary
		.state('proposal.summary', {
			url: '/summary',
			title: 'Consultation Summary',
			template: '<summary-page/>',
			data: {
				permissions: {
					only: ['isDraft'],
					redirectTo: manageRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName "summaryPageMod" */
						'./app/containers/summary-page/summary-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user
		.state('user', {
			abstract: true,
			url: '/user',
			template: '<user-abstract-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($log, $q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "userAbstractPageMod" */
						'./app/containers/user-abstract-page/user-abstract-page.module.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch($log.error);
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user.auth
		.state('user.auth', {
			url: '/auth',
			title: 'Auth',
			template: '<auth-page system-flags="$resolve.systemFlags"></auth-page>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "authPageMod" */
						'./app/containers/user-auth-page/user-auth-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				systemFlags: ($cookies, INIT_FLAGS) => $cookies.getObject(INIT_FLAGS.ALERT_TIME_NOT_SYNC),
			},
		})
		// #endregion
		// #region user.login
		.state('user.login', {
			url: '/login',
			title: 'Login',
			template: '<login-page></login-page>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "loginPageMod" */
						'./app/containers/user-login-page/user-login-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user.logout
		.state('user.logout', {
			url: '/logout',
			title: 'Logout',
			template: '<logout-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "logoutPageMod" */
						'./app/containers/user-logout-page/user-logout-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user.setPassword
		.state('user.setPassword', {
			url: '/set-password?token',
			title: 'Set Password',
			template: '<set-pass-page/>',
			params: {
				token: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "setPassPageMod" */
						'./app/containers/user-set-pass-page/user-set-pass-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.resolve(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user.resetpassword
		.state('user.resetpassword', {
			url: '/resetpassword',
			title: 'Reset Password',
			template: '<change-pass-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "changePassPageMod" */
						'./app/containers/user-change-pass-page/change-pass-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.resolve(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user.forgot
		.state('user.forgotPass', {
			url: '/forgotpassword?token',
			title: 'Forgot Password',
			template: '<forgot-pass-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "forgotPassPageMod" */
						'./app/containers/user-forgot-pass-page/user-forgot-pass-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.resolve(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region info
		.state('user.info', {
			url: '/info?topic',
			title: 'Info',
			template: '<info-page></info-page>',
			params: {
				topic: null,
				data: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "infoPageMod" */
						'./app/containers/user-info-page/user-info-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region user.super
		.state('user.super', {
			url: '/super',
			title: 'User Super',
			template: '<super-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "superPageMod" */
						'./app/containers/user-super-page/super-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view abstract (consumer)
		.state('view', {
			url: '/view',
			abstract: true,
			template: '<view-abstract-page/>',
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "viewAbstractPageMod" */
						'./app/containers/view-abstract-page/view-abstract-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.consultation
		.state('view.consultation', {
			url: '/consultation/:customerToken/:serviceProviderId/:consultationId',
			title: 'Consultation Comparison',
			template: '<comparison-page/>',
			data: {
				permissions: {
					only: ['isPresentedAndPublic'],
					redirectTo: managePublicRejectedPermission,
				},
			},
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "comparisonPageMod" */
						'./app/containers/comparison-page/comparison-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.proposal
		.state('view.proposal', {
			url: '/proposal/:customerToken/:serviceProviderId/:consultationId/:proposalId?showBack',
			title: 'Proposal',
			template: '<proposal-page provider-flags="$resolve.providerFlags"/>',
			data: {
				permissions: {
					only: ['isPresentedAndPublic'],
					redirectTo: managePublicRejectedPermission,
				},
			},
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
				proposalId: null,
				showBack: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "proposalPageMod" */
						'./app/containers/proposal-page/proposal-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				providerFlags: ($stateParams, RoutesResolveSvc) =>
					RoutesResolveSvc.getProviderFlagsPublic({fromState: 'view.proposal', ...$stateParams}),
			},
		})
		// #endregion
		// #region view.accepted
		.state('view.accepted', {
			url: '/accepted/:customerToken/:serviceProviderId/:consultationId/:proposalId?showBack',
			title: 'Proposal',
			template: '<proposal-page provider-flags="$resolve.providerFlags"/>',
			data: {
				permissions: {
					only: ['isAcceptedAndPublic'],
					redirectTo: managePublicRejectedPermission,
				},
			},
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
				proposalId: null,
				showBack: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "proposalPageMod" */
						'./app/containers/proposal-page/proposal-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				providerFlags: ($stateParams, RoutesResolveSvc) =>
					RoutesResolveSvc.getProviderFlagsPublic({fromState: 'view.accepted', ...$stateParams}),
			},
		})
		// #endregion
		// #region view.confirmation
		.state('view.confirmation', {
			url: '/confirmation/:customerToken/:serviceProviderId/:consultationId/:proposalId',
			title: 'Proposal Confirmation',
			template: '<confirmation-page></confirmation-page>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "confirmationPageMod" */
						'./app/containers/view-confirmation-page/view-confirmation-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.inspector
		.state('view.inspector', {
			url: '/inspector/:customerToken/:serviceProviderId/:consultationId/:proposalId',
			title: 'Proposal Inspector',
			template: '<inspector-page/>',
			data: {
				permissions: {
					only: ['isAcceptedAndPublic'],
					redirectTo: managePublicRejectedPermission,
				},
			},
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
				proposalId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "inspectorPageMod" */
						'./app/containers/inspector-page/inspector-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.lost public
		.state('view.lost', {
			url: '/lost/:customerToken/:serviceProviderId/:consultationId',
			title: 'Lost Consultation',
			template: '<lost-page/>',
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
			},
			data: {
				permissions: {
					only: ['isLostAndPublic'],
					redirectTo: managePublicRejectedPermission,
				},
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "lostPageMod" */
						'./app/containers/view-lost-page/view-lost-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.rejected(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.unavailable for emailed consultations in the wrong status
		.state('view.unavailable', {
			url: '/unavailable/:customerToken/:serviceProviderId/:consultationId/:proposalId',
			title: 'Proposal Unavailable',
			template: '<unavailable-page></unavailable-page>',
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
				proposalId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "unavailablePageMod" */
						'./app/containers/view-unavailable-page/view-unavailable-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.rejected(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.expired
		.state('view.expired', {
			url: '/expired/:customerToken/:serviceProviderId/:consultationId',
			title: 'Expired',
			template: '<expired-page></expired-page>',
			params: {
				customerToken: null,
				serviceProviderId: null,
				consultationId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "expiredPageMod" */
						'./app/containers/view-expired-page/view-expired-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region view.legacy
		.state('view.legacy', {
			url: '/unavailable',
			title: 'Consultation Legacy Data Unavailable',
			template: '<legacy-unavailable> </legacy-unavailable>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "legacyUnavailablePageMod" */
						'./app/containers/legacy-unavailable-page/legacy-unavailable-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region preview abstract (consumer)
		.state('preview', {
			url: '/preview',
			abstract: true,
			template: '<view-abstract-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "viewAbstractPageMod" */
						'./app/containers/view-abstract-page/view-abstract-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				logAccess: RoutesResolveSvc => RoutesResolveSvc.postLastUserAccess({fromState: 'preview'}),
			},
		})
		// #endregion
		// #region preview.consultation
		.state('preview.consultation', {
			url: '/consultation/:consultationId/:proposalId?showBack',
			title: 'Consultation Comparison',
			template: '<comparison-page/>',
			data: {
				permissions: {
					only: ['isPresentedAndPrivate'],
					redirectTo: manageRejectedPermission,
				},
			},
			params: {
				consultationId: null,
				showBack: null,
				// necessary to intercept redirect on accepted from to /preview/consultation to /preview/accepted
				proposalId: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "comparisonPageMod" */
						'./app/containers/comparison-page/comparison-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region preview.proposal
		.state('preview.proposal', {
			url: '/proposal/:consultationId/:proposalId?showBack',
			title: 'Preview Proposal',
			template: '<proposal-page provider-flags="$resolve.providerFlags"/>',
			data: {
				permissions: {
					only: ['isPresentedAndPrivate'],
					redirectTo: managePublicRejectedPermission,
				},
			},
			params: {
				consultationId: null,
				proposalId: null,
				showBack: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "proposalPageMod" */
						'./app/containers/proposal-page/proposal-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				providerFlags: ($stateParams, RoutesResolveSvc) =>
					RoutesResolveSvc.getProviderFlags({fromState: 'preview.proposal', ...$stateParams}),
			},
		})
		// #endregion
		// #region preview.accepted
		.state('preview.accepted', {
			url: '/accepted/:consultationId/:proposalId?showBack?preview',
			title: 'Accepted Proposal',
			template: '<proposal-page provider-flags="$resolve.providerFlags"/>',
			data: {
				permissions: {
					only: ['isAcceptedAndPrivate'],
					redirectTo: manageRejectedPermission,
				},
			},
			params: {
				consultationId: null,
				proposalId: null,
				showBack: null,
				preview: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "proposalPageMod" */
						'./app/containers/proposal-page/proposal-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				providerFlags: ($stateParams, RoutesResolveSvc) =>
					RoutesResolveSvc.getProviderFlags({fromState: 'preview.accepted', ...$stateParams}),
			},
		})
		// #endregion
		// #region preview.lost
		.state('preview.lost', {
			url: '/lost/:consultationId?showBack',
			title: 'Accepted Proposal',
			template: '<lost-page/>',
			data: {
				permissions: {
					only: ['isLostAndPrivate'],
					redirectTo: manageRejectedPermission,
				},
			},
			params: {
				consultationId: null,
				showBack: 'true',
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($log, $q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "previewLostPageMod" */
						'./app/containers/view-lost-page/view-lost-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => $log.error('proposal.lost', err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region customersummary (legacy emailed comparison)
		.state('customersummary', {
			url: '/customersummary/:proposalId/:serviceProviderId/:customerToken',
			title: 'Consultation Comparison',
			resolve: {
				/** @ngInject */
				legacyData: (ConsultationService, $log, $stateParams, $state, $q) => {
					const _deferred = $q.defer();
					ConsultationService.getConsultationFromLegacyPresentedProposal({
						proposalId: $stateParams.proposalId,
						serviceProviderId: $stateParams.serviceProviderId,
						customerToken: $stateParams.customerToken,
					})
						.then(resObj => {
							_deferred.reject(resObj);
						})
						.catch(err => {
							_deferred.reject(err);
						});

					return _deferred.promise.catch(res => {
						if (Object.prototype.hasOwnProperty.call(res.data, 'success')) {
							$state.go('view.consultation', {
								customerToken: $stateParams.customerToken,
								serviceProviderId: $stateParams.serviceProviderId,
								consultationId: res.data.payLoad.consultation_id,
							});
						} else {
							$log.error(res.data.error);
							$state.go('view.legacy');
						}
					});
				},
			},
			params: {
				proposalId: null,
				serviceProviderId: null,
				customerToken: null,
			},
		})
		// #endregion
		// #region legacy customer details
		.state('customerdetails', {
			url: '/customerdetails/:proposalId/:serviceProviderId/:customerToken/:position',
			title: 'Proposal Details',
			resolve: {
				/** @ngInject */
				legacyData: (ConsultationService, $log, $stateParams, $state, $q) => {
					const _deferred = $q.defer();
					ConsultationService.getConsultationFromLegacyPresentedProposalWithPosition({
						proposalId: $stateParams.proposalId,
						serviceProviderId: $stateParams.serviceProviderId,
						customerToken: $stateParams.customerToken,
						proposalPosition: $stateParams.position,
					})
						.then(resObj => {
							_deferred.reject(resObj);
						})
						.catch(err => {
							_deferred.reject(err);
						});

					return _deferred.promise.catch(res => {
						if (Object.prototype.hasOwnProperty.call(res.data, 'success')) {
							$state.go('view.proposal', {
								customerToken: $stateParams.customerToken,
								serviceProviderId: $stateParams.serviceProviderId,
								consultationId: res.data.payLoad.consultation_id,
								proposalId: res.data.payLoad.proposal_id,
							});
						} else {
							$log.error(res.data.error);
							$state.go('view.legacy');
						}
					});
				},
			},
			params: {
				proposalId: null,
				serviceProviderId: null,
				customerToken: null,
				position: null,
			},
		})
		// #endregion
		// #region legacy customer accepted
		.state('customeraccepted', {
			url: '/accepted/:proposalId/:serviceProviderId/:customerToken',
			title: 'Accepted Proposal',
			resolve: {
				/** @ngInject */
				legacyData: (ConsultationService, $log, $stateParams, $state, $q) => {
					const _deferred = $q.defer();
					ConsultationService.getConsultationFromLegacyAcceptedProposal({
						proposalId: $stateParams.proposalId,
						serviceProviderId: $stateParams.serviceProviderId,
						customerToken: $stateParams.customerToken,
					})
						.then(resObj => {
							_deferred.reject(resObj);
						})
						.catch(err => {
							_deferred.reject(err);
						});

					return _deferred.promise.catch(res => {
						if (Object.prototype.hasOwnProperty.call(res.data, 'success')) {
							$state.go('view.accepted', {
								customerToken: $stateParams.customerToken,
								serviceProviderId: $stateParams.serviceProviderId,
								consultationId: res.data.payLoad.consultation_id,
								proposalId: res.data.payLoad.proposal_id,
							});
						} else {
							$log.error(res.data.error);
							$state.go('view.legacy');
						}
					});
				},
			},
			params: {
				proposalId: null,
				serviceProviderId: null,
				customerToken: null,
			},
		})
		// #endregion
		// #region office abstract
		.state('office', {
			url: '/office',
			abstract: true,
			template: '<office-abstract-page flags-capabilities-access="$resolve.flagsCapabilitiesAccess"/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAbstractPageMod" */
						'./app/containers/office-abstract-page/office-abstract-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
				/** @ngInject */
				isAdmin: ($q, $timeout, AuthService) => {
					const _deferred = $q.defer();
					$timeout(() => {
						const isLogged = AuthService.isLoggedIn();
						if (isLogged) {
							_deferred.resolve('is logged in');
						} else {
							_deferred.reject('is not logged in');
						}
					}, 100);
					return _deferred.promise;
				},
				/** @ngInject */
				flagsCapabilitiesAccess: (RoutesResolveSvc, $state) =>
					RoutesResolveSvc.getFlagsCapabilitiesLogAccess({fromState: 'office'})
						.then(data => data)
						.catch(() => $state.go('user.auth')),
			},
		})
		// #endregion
		// #region office.liveEquipment
		.state('office.liveEquipment', {
			url: '/live-equipment',
			template: '<office-live-equipment-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeLiveEquipmentPageMod" */
						'./app/containers/office-live-equipment-page/office-live-equipment.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.alternateEquipment
		.state('office.alternateEquipment', {
			url: '/alternate-equipment',
			template: '<office-alternate-equipment-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAlternateEquipmentPageMod" */
						'./app/containers/office-alternate-equipment-page/office-alternate-equipment.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.rebadisc
		.state('office.rebadisc', {
			url: '/rebate-discounts?tab',
			template: '<rebates-discounts-page/>',
			params: {
				tab: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeDiscountAndRebatesPageMod" */
						'./app/containers/office-rebate-discounts-page/office-rebates-discounts-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// todo: refactor routes name to make work highlighting sidebar menu (same way as installations, users and flexjobs)
		// #region office.rebadiscCreateEditRebate
		.state('office.rebadiscCreateEditRebate', {
			url: '/rebate/:id',
			params: {
				id: null,
			},
			template: '<create-edit-rebate-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeCreateRebatePageMod" */
						'./app/containers/office-rebate-discounts-page/office-create-edit-rebate/office-create-edit-rebate-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.rebadiscCreateEditDiscount
		.state('office.rebadiscCreateEditDiscount', {
			url: '/discount/:id',
			params: {
				id: null,
			},
			template: '<create-edit-discount-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeCreateDiscountPageMod" */
						'./app/containers/office-rebate-discounts-page/office-create-edit-discount/office-create-edit-discount.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.flexjobs
		.state('office.flexjobs', {
			url: '/flexjobs',
			template: '<flexjobs-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFlexjobsPageMod" */
						'./app/containers/office-flexjobs-page/office-flexjobs-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		.state('office.flexjobsCreate', {
			url: '/flexjob/create',
			template: '<create-flexjob-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFlexjobsCreatePageMod" */
						'./app/containers/office-flexjobs-page/create/office-flexjobs-create.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.flexjobsEdit
		.state('office.flexjobsEdit', {
			url: '/flexjob/edit/:id',
			template: '<edit-flexjob-page/>',
			params: {
				id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFlexjobsEditPageMod" */
						'./app/containers/office-flexjobs-page/edit/office-flexjobs-edit.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.users
		.state('office.users', {
			url: '/users',
			template: '<users-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeUsersPageMod" */
						'./app/containers/office-users-page/office-users-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.usersUpdate
		.state('office.usersUpdate', {
			url: '/users/:id',
			template: '<user-edit/>',
			params: {
				id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeUsersEditPageMod" */
						'./app/containers/office-users-page/update/office-user-update.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.usersCreate
		.state('office.usersCreate', {
			url: '/create',
			template: '<user-create/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeUsersCreatePageMod" */
						'./app/containers/office-users-page/create/office-user-create.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.economic-profile
		.state('office.economic-profile', {
			url: '/economic-profile',
			template: '<economic-profile/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeEconomicProfilePageMod" */
						'./app/containers/office-pricing-engine/office-pricing-engine.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.dashboard
		.state('office.dashboard', {
			url: '/dashboard',
			template: '<dashboard-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeDashboardPageMod" */
						'./app/containers/office-dashboard-page/office-dashboard-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.bundleManager
		.state('office.bundleManager', {
			url: '/bundle-manager?system&profile&type',
			template: '<office-bundle-manager/>',
			params: {
				system: null,
				profile: null,
				type: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeBundleManagerPageMod" */
						'./app/containers/office-bundle-manager-page/bundle-manager-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.fileImport
		.state('office.fileImport', {
			url: '/file-import',
			template: '<office-file-import/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFileImportCmpMod" */
						'./app/containers/office-file-importer/office-file-importer.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office accessories
		.state('office.accessories', {
			url: '/accessories',
			template: '<office-accessories-list-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAccessoriesPageMod" */
						'./app/containers/office-accessories-page/office-accessories-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.accessoriesLiveUpdate
		.state('office.accessoriesLiveUpdate', {
			url: '/accessories/update-live/:bu_id/:prod_id',
			template: '<office-accessories-update-page/>',
			params: {
				prod_id: null,
				bu_id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAccessoriesLiveUpdatePageMod" */
						'./app/containers/office-accessories-page/live-update/office-accessories-edit.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.accessoriesCustomUpdate
		.state('office.accessoriesCustomUpdate', {
			url: '/accessories/update-custom/:bu_id/:prod_id',
			template: '<office-acc-custom-update-page/>',
			params: {
				prod_id: null,
				bu_id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAccessoriesCustomUpdatePageMod" */
						'./app/containers/office-accessories-page/custom-update/office-accessories-custom-edit.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.accessoriesCreateCustom
		.state('office.accessoriesCreateCustom', {
			url: '/accessories/create',
			template: '<office-accessories-create-custom-page/>',
			params: {
				prod_id: null,
				bu_id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAccessoriesCustomCreatePageMod" */
						'./app/containers/office-accessories-page/custom-create/office-accessories-custom-create.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.accessoriesCreateLive
		.state('office.accessoriesCreateLive', {
			url: '/accessories/create/:bu_id/:prod_id',
			template: '<office-accessories-create-live/>',
			params: {
				prod_id: null,
				bu_id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAccessoriesLiveCreatePageMod" */
						'./app/containers/office-accessories-page/live-create/office-accessories-create.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.accessoriesSearchLive
		.state('office.accessoriesSearchLive', {
			url: '/accessories/search',
			template: '<office-accessories-search-live/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeAccessoriesLiveSearchPageMod" */
						'./app/containers/office-accessories-page/search-live/office-accessories-search-live.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.installation
		.state('office.installation', {
			url: '/installation',
			template: '<office-installation-list-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeInstallationPageMod" */
						'./app/containers/office-installation-page/office-installation-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.installationCreate
		.state('office.installationCreate', {
			url: '/installation/create',
			template: '<office-installation-create-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeInstallationCreatePageMod" */
						'./app/containers/office-installation-page/office-create-installation/office-create-installation.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.installationEdit
		.state('office.installationEdit', {
			url: '/installation/edit',
			template: '<office-installation-edit-page/>',
			params: {obj: null},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeInstallationUpdatePageMod" */
						'./app/containers/office-installation-page/office-edit-installation/office-edit-installation.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.consultations
		.state('office.consultations', {
			url: '/consultations',
			template: '<consultation-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeConsultationsPageMod" */
						'./app/containers/office-consultation-page/office-consultation-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.company-profile
		.state('office.company-profile', {
			url: '/company-profile',
			template: '<company-profile-page></company-profile-page>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeCompanyProfilePageMod" */
						'./app/containers/office-my-company-page/office-my-company-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.connectors
		.state('office.connectors', {
			url: '/connectors?id&logout',
			template: '<office-connectors-page/>',
			params: {
				id: null,
				logout: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeConnectorsPageMod" */
						'./app/containers/office-connectors-page/office-connectors-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.financeOption
		.state('office.financeOption', {
			url: '/finance-option',
			template: '<finance-options-page/>',
			params: {
				id: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFinanceOptionsPageMod" */
						'./app/containers/office-finance-options/finance-options-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.financeOptionStandard
		.state('office.financeOptionStandard', {
			url: '/finance-option-standard/:type_id/:id',
			template: '<finance-option-standard/>',
			params: {
				id: null,
				type_id: null,
				type_name: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFinanceOptionStandardPageMod" */
						'./app/containers/office-finance-options/finance-option-standard.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.financeOptionText
		.state('office.financeOptionText', {
			url: '/finance-option-text/:type_id/:id',
			template: '<finance-option-text/>',
			params: {
				id: null,
				type_id: null,
				type_name: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFinanceOptionTextPageMod" */
						'./app/containers/office-finance-options/finance-option-text-only.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.financeOptionFinancePlus
		.state('office.financeOptionFinancePlus', {
			url: '/finance-option-finance-plus/:type_id/:id',
			template: '<finance-option-finance-plus/>',
			params: {
				id: null,
				type_id: null,
				type_name: null,
			},
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeFinanceOptionFinancePlusPageMod" */
						'./app/containers/office-finance-options/finance-option-finance-plus.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.settings-proposal-labels
		.state('office.settings-proposal-labels', {
			url: '/settings-proposal-labels',
			template: '<proposal-labels/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeSettingsProposalLabelsPageMod" */
						'./app/containers/office-proposal-labels/office-proposal-labels.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.settings-survey
		.state('office.settings-survey', {
			url: '/settings-survey',
			template: '<settings-survey-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeSettingsSurveyPageMod" */
						'./app/containers/office-survey-page/office-survey-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.email-templates
		.state('office.email-templates', {
			url: '/email-templates',
			template: '<email-template-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeSettingsEmailPageMod" */
						'./app/containers/office-email-template-page/settings-email.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.email-followups
		.state('office.email-followups', {
			url: '/email-followups',
			template: '<email-followup-page/>',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeSettingsEmailFollowUpPageMod" */
						'./app/containers/office-email-followup-page/office-email-followup-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		})
		// #endregion
		// #region office.customEquipment
		.state('office.customEquipment', OfficeCustomEquipmentListState)
		// #endregion
		// #region office.customEquipmentUpdate
		.state('office.customEquipmentUpdate', OfficeCustomEquipmentUpdateRoute)
		// #endregion
		// #region office.customEquipmentCreate
		.state('office.customEquipmentCreate', OfficeCustomEquipmentCreateRoute)
		// #endregion
		// #region office.reports
		.state('office.reports', {
			url: '/reports',
			template: '<office-reports-page />',
			resolve: {
				/** @ngInject */
				loadComponent: ($q, $ocLazyLoad) => {
					const _deferred = $q.defer();
					import(
						/* webpackChunkName: "officeDataReportsPageMod" */
						'./app/containers/office-reports-page/office-reports-page.component.js'
					)
						.then(res => {
							$ocLazyLoad.load({name: res.default});
							_deferred.resolve(res);
						})
						.catch(err => _deferred.reject(err));
					return _deferred.promise;
				},
			},
		});
	// #endregion

	const $urlRouterProvider = $injector.get('$urlRouterProvider');
	$urlRouterProvider.otherwise('/navigator');
};
routesConfig.$inject = ['$injector'];
export default routesConfig;
