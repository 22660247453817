const bold = `<button type="button" class="btn-sm" aria-label="Format bold">
	<svg-icon icon-name="'type-bold'"></svg-icon>
</button type="button">`;
const italics = `<button type="button" class="btn-sm" aria-label="Format italics">
	<svg-icon icon-name="'type-italic'"></svg-icon>
</button type="button">`;
const underline = `<button type="button" class="btn-sm" aria-label="Format underline">
	<svg-icon icon-name="'type-underline'"></svg-icon>
</button>`;
const strikeThrough = `<button type="button" class="btn-sm" aria-label="Format strikethrough">
	<svg-icon icon-name="'type-strikethrough'"></svg-icon>
</button>`;
const ul = `<button type="button" class="btn-sm" aria-label="Format list ul">
	<svg-icon icon-name="'list-ul'"></svg-icon>
</button>`;
const ol = `<button type="button" class="btn-sm" aria-label="Format list ol">
	<svg-icon icon-name="'list-ol'"></svg-icon>
</button>`;
const undo = `<button type="button" class="btn-sm" aria-label="Format undo">
	<svg-icon icon-name="'chevron-left'"></svg-icon>
</button>`;
const redo = `<button type="button" class="btn-sm" aria-label="Format redo">
	<svg-icon icon-name="'chevron-right'"></svg-icon>
</button>`;
const clear = `<button type="button" class="btn-sm" aria-label="Format clear">
	<svg-icon icon-name="'x-lg'"></svg-icon>
</button>`;
const insertLink = `<button type="button" class="btn-sm" aria-label="Format insert link">
	<svg-icon icon-name="'link-45deg'"></svg-icon>
</button>`;
const paragraph = `<button type="button" class="btn-sm" aria-label="Format paragraph">
	<svg-icon icon-name="'paragraph'"></svg-icon>
</button>`;

// Read more: https://github.com/textAngular/textAngular/blob/master/src/textAngularSetup.js
const configTextAngularConfig = $provide => {
	$provide.decorator('taOptions', taOptionsDecorator);
	taOptionsDecorator.$inject = ['$delegate'];
	function taOptionsDecorator(taOptions) {
		taOptions.forceTextAngularSanitize = false;
		taOptions.toolbar = [
			['p'],
			['bold', 'italics', 'underline', 'strikeThrough', 'insertLink', 'clear'],
			['ul', 'ol'],
			['undo', 'redo'],
		];
		return taOptions;
	}

	$provide.decorator('taTools', taToolsDecorator);
	taToolsDecorator.$inject = ['$delegate'];
	function taToolsDecorator(taTools) {
		taTools.p.display = paragraph;
		taTools.insertLink.display = insertLink;
		taTools.bold.display = bold;
		taTools.italics.display = italics;
		taTools.underline.display = underline;
		taTools.strikeThrough.display = strikeThrough;
		taTools.ul.display = ul;
		taTools.ol.display = ol;
		taTools.redo.display = redo;
		taTools.undo.display = undo;
		taTools.clear.display = clear;
		return taTools;
	}
};
configTextAngularConfig.$inject = ['$provide'];
export default configTextAngularConfig;
