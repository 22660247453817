const onUnhandledRejection = $injector => {
	const $window = $injector.get('$window');
	const $log = $injector.get('$log');
	const HAS_CHUNK_ERROR = 'HAS_CHUNK_ERROR';
	const HAS_CRITICAL_ERROR = 'HAS_CRITICAL_ERROR';
	const LOAD_FAILED = ['CSS_CHUNK_LOAD_FAILED'];

	$window.addEventListener('unhandledrejection', handleRejection, false);

	function handleRejection(e) {
		$log.error('onUnhandledRejection > event: ', e.promise, e.reason);
		const errorType = getDerivedStateFromError(e.reason);

		if (caches && e.reason?.request) {
			// Browser support caches, we can check chunk existence
			caches.match(e.reason.request).then(res => {
				if (res === undefined) {
					// chunk is not in cache => application encounter 404
					if ($window.navigator.onLine === true) {
						// user is online so missing chunk come from new version, should update the service worker to update app
						console.log('OfflinePluginRuntime.update'); // eslint-disable-line
						// OfflinePluginRuntime.update();
						if (errorType.code === HAS_CHUNK_ERROR) {
							$window.location.reload();
						} else {
							$log.warn('log > errorType.code >', errorType.code); // eslint-disable-line
						}
					} else {
						// user is disconnected, missing chunk come from offline
						$log.warn('Device is offline');
					}
				} else {
					// if chunk is found, then chunk may be corrupted, should update the service worker to update app
					$log.info('Chunk is cache: ', res);
					return res;
				}
			});
		} else {
			$log.warn('Unable to tap "cache" CacheStorage object');
		}

		// Prevent the default handling (such as outputting the error to the console)
		e.preventDefault();
	}

	function getDerivedStateFromError(reason) {
		const msgRe = /.* Loading [\w]+ chunk [\w+]+ failed/;
		if (reason?.name && reason?.name === 'ChunkLoadError') return {code: HAS_CHUNK_ERROR};
		if (reason?.code && LOAD_FAILED.includes(reason?.code)) return {code: HAS_CHUNK_ERROR};
		if (msgRe.test(reason.message)) return {code: HAS_CHUNK_ERROR};
		return {code: HAS_CRITICAL_ERROR};
	}
};
onUnhandledRejection.$inject = ['$injector'];
export default onUnhandledRejection;
