class InterceptorStatusCodes {
	constructor($q, $log, $rootScope, $injector) {
		'ngInject';
		this.$q = $q;
		this.$log = $log;
		this.$rootScope = $rootScope;
		this.$injector = $injector;
		//
		this.response = this.responseFn.bind(this);
		this.responseError = this.responseErrorFn.bind(this);
	}

	/**
	 * Response callback
	 * @param {Object} response object
	 * @returns A config object
	 * @memberof InterceptorStatusCodes
	 */
	responseFn(response) {
		if (response?.data?.warning?.code === 763) {
			// grab the status from the error message
			const state = this.$injector.get('$state');
			const _status = response.data.warning.message.match(/.*: (\w+)/)[1];
			const ModalHelperService = this.$injector.get('ModalHelperService');
			ModalHelperService.alertModal(
				'sm',
				false,
				`Hey! It seems like this proposal is already ' ${_status}`,
				`<p class="text-center">Let's get you to the main page</p>`,
				{label: 'Cancel', disabled: true},
				{label: 'OK', disabled: false},
				'static'
			)
				.result.then(res => {
					this.$log.warn(res.data.warning);
					state.go('navigator');
				})
				.catch(angular.noop);

			// abort next request as user will be redirected
			const _def = this.$q.defer();
			return _def.promise;
		}
		return response;
	}

	responseErrorFn(rejection) {
		if (rejection.status === 503) {
			const _def = this.$q.defer();
			this.$rootScope.$broadcast('event:service-unavailable', rejection.data.error);
			return _def.promise;
		}

		const errorObj = {data: {error: {code: 504, message: 'The request timeout or got aborted, status -1'}}};
		if (rejection.status === -1) return this.$q.reject(Object.assign(rejection, errorObj));

		return this.$q.reject(rejection);
	}

	static create($q, $log, $rootScope, $injector) {
		return new InterceptorStatusCodes($q, $log, $rootScope, $injector);
	}
}
InterceptorStatusCodes.create.$inject = ['$q', '$log', '$rootScope', '$injector'];
export default InterceptorStatusCodes;
