import publicStates from '@Constants/states-public.constant.js';
import navStates from '@Constants/states-navigation.constant.js';
//
export class NavService {
	constructor($log, NAV_STATES, StorageService, UtilityService, $q, ConsultationService, AuthService, PUBLIC_STATES) {
		'ngInject';
		this.$q = $q;
		this.$log = $log;
		this.AuthService = AuthService;
		this.utilityService = UtilityService;
		this.storageService = StorageService;
		this.consultationService = ConsultationService;
		this.NAV_STATES = NAV_STATES;
		this.PUBLIC_STATES = PUBLIC_STATES;
	}

	getNavStates() {
		return this.storageService.get('states') || this.NAV_STATES;
	}

	enableNavigationToState(name) {
		const _nav_states = this.getNavStates();
		let _targetIdx;
		return _nav_states.map((x, i) => {
			x.disabled = angular.isDefined(_targetIdx);
			if (x.name === name) _targetIdx = i;
			return x;
		});
	}

	getStateIdx(stateName) {
		const _nav_states = this.getNavStates();
		return this.utilityService.getObjIdxByAttrValue(_nav_states, 'name', stateName);
	}

	enableNavItem(stateName) {
		const _nav_states = this.getNavStates();
		const _idx = this.utilityService.getObjIdxByAttrValue(_nav_states, 'name', stateName);
		if (_idx !== -1) {
			_nav_states[_idx].disabled = false;
			this.storageService.set('states', _nav_states);
			return true;
		}
		return false;
	}

	isBehindStored(stateName) {
		var _consultation = this.storageService.get('consultation');
		var _nav_states = this.getNavStates();
		var _storedIdx = this.utilityService.getObjIdxByAttrValue(
			_nav_states,
			'name',
			_consultation.consultation_client_state
		);
		var _currIdx = this.utilityService.getObjIdxByAttrValue(_nav_states, 'name', stateName);
		return _currIdx < _storedIdx;
	}

	isParentVisible(toStateName) {
		// * -> accepted.inspector
		if (/\.+inspector$/.test(toStateName)) {
			return false;
		}

		// * -> summary$
		if (/\.+summary$/.test(toStateName)) {
			return true;
		}

		// * -> summary$
		if (/\.+comparison$/.test(toStateName)) {
			return false;
		}

		return true;
	}

	saveClientState(stateCurrent, stateValid) {
		const currentUser = this.AuthService.currentUser();

		if (!currentUser || !currentUser?.id) {
			this.$log.error('Current user is required');
		}

		const _consultation = this.storageService.get('consultation');
		// fixme: ensure that _consultation has all required keys
		if (!_consultation?.consultation_current_client_state) {
			_consultation['consultation_current_client_state'] = null;
		}
		if (!_consultation?.consultation_client_state) {
			_consultation['consultation_client_state'] = null;
		}

		// stored current AND valid are equal resolve promise with update states, skip calling API
		const {consultation_current_client_state, consultation_client_state} = _consultation;
		if (consultation_current_client_state === stateCurrent && consultation_client_state === stateValid) {
			return this.$q.when(this.enableNavigationToState(stateValid));
		}

		// reject promise if no valid param values todo: validate this line to be removed or move to top
		if (!stateCurrent && !stateValid) this.$q.reject({message: 'Invalid request Current and Valid are required'});

		const _errMsg = {display: 'Unable to save client state'};
		const _deferred = this.$q.defer();
		this.consultationService
			.postSaveConsultationState({
				serviceProviderId: currentUser.service_provider_id,
				userId: currentUser.id,
				consultationId: _consultation.consultation_id,
				// can navigate to clientState
				clientState: stateValid,
				currentClientState: stateCurrent,
			})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) {
					_consultation.consultation_current_client_state = stateCurrent;
					_consultation.consultation_client_state = stateValid;
					this.storageService.set('consultation', _consultation);

					// update states in navbar
					const _nav_states = this.enableNavigationToState(stateValid);
					this.storageService.set('states', _nav_states);
					_deferred.resolve(_nav_states);
				}
				if (data?.error) _deferred.reject(Object.assign(data.error, _errMsg));
			})
			.catch(err => _deferred.reject(Object.assign(err.data.error, _errMsg)));
		return _deferred.promise;
	}

	/**
	 * @param {string} stateName - state name
	 * @returns boolean
	 */
	isStatePublic(stateName) {
		if (!stateName) {
			this.$log.error('State name required.');
			return false;
		}
		return this.PUBLIC_STATES.includes(stateName);
	}

	currentUser() {
		return this.AuthService.currentUser();
	}
}
//
let compMod = angular
	.module('mod.svc.NavService', [])
	.constant('PUBLIC_STATES', publicStates)
	.constant('NAV_STATES', navStates)
	.service('NavService', NavService);
export default compMod = compMod.name;
