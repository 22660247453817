const permissionsDefinitions = $injector => {
	const $q = $injector.get('$q');
	const $log = $injector.get('$log');
	const $cookies = $injector.get('$cookies');
	const StorageService = $injector.get('StorageService');
	const NavService = $injector.get('NavService');
	const PermPermissionStore = $injector.get('PermPermissionStore');
	const ConsultationService = $injector.get('ConsultationService');

	function updateLocalClientState(state, status) {
		const consultation = StorageService.get('consultation');
		if (consultation) {
			consultation.consultation_client_state = state;
			consultation.status = status;
			return StorageService.set('consultation', consultation);
		} else {
			$log.error(
				'Unable to set consultation status from permissionsDefinitions, consultation data not in session storage'
			);
		}
	}

	function getProposalAndUserData() {
		const _consultation = StorageService.get('consultation');
		const _user = $cookies.getObject('OCAUS');
		if (_consultation || _user) {
			return {
				consultationId: _consultation.consultation_id,
				serviceProviderId: _user.service_provider_id,
				userId: _user.id,
				// clientState: _consultation.consultation_client_state,
			};
		} else {
			$log.error('Unable to get "user" or "consultation" keys from local storage.');
			return null;
		}
	}

	// #region DRAFT
	const privatePermissionsObj = {
		isDraft: {
			requiredStatus: 'draft',
		},
	};
	const privatePermissionsArr = Object.keys(privatePermissionsObj);
	PermPermissionStore.defineManyPermissions(privatePermissionsArr, (permissionName, transitionProperties) => {
		const _data = getProposalAndUserData();
		const _deferred = $q.defer();

		// fixme: remove after passing consultation id thru the url
		if (transitionProperties.toState.name === 'proposal.customer' && !_data?.consultationId) {
			_deferred.resolve();
		} else if (!_data || !_data.consultationId) {
			_deferred.reject({state: 'navigator'});
		} else {
			ConsultationService.postValidateConsultationStateAndStatus({
				consultationId: _data.consultationId,
				serviceProviderId: _data.serviceProviderId,
				userId: _data.userId,
			})
				.then(resObj => {
					const {data} = resObj;
					if (data && data?.success) {
						const {consultation_status_system_name: status, consultation_client_state} = data.payLoad;
						let _toState, _toParams;

						if (privatePermissionsObj[permissionName].requiredStatus === status) {
							_deferred.resolve();
						} else {
							switch (status) {
								case 'lost':
									_toState = 'preview.lost';
									_toParams = {consultationId: _data.consultationId, showBack: true};
									break;

								case 'presented':
									_toState = 'preview.consultation';
									_toParams = {consultationId: _data.consultationId, showBack: true};
									break;

								case 'accepted':
									_toState = 'preview.proposal';
									_toParams = {consultationId: _data.consultationId, proposalId: data.proposal_id, showBack: true};
									break;

								default:
									_toState = 'navigator';
									break;
							}

							$log.error(`Status should match permission, value: ${status} and expected "draft"`);

							updateLocalClientState(consultation_client_state, status);
							_deferred.reject({state: _toState, params: _toParams});
						}
					}

					if (data?.error) _deferred.reject(data.error);
				})
				.catch(err => _deferred.reject(err));
		}

		return _deferred.promise;
	});
	// #endregion

	// #region  PRESENTATION PUBLIC
	const publicPermissionsObj = {
		isPresentedAndPublic: {
			requiredStatus: 'presented',
		},
		isAcceptedAndPublic: {
			requiredStatus: 'accepted',
		},
		isLostAndPublic: {
			requiredStatus: 'lost',
		},
	};
	const publicPermissionsArr = Object.keys(publicPermissionsObj);
	PermPermissionStore.defineManyPermissions(publicPermissionsArr, (permissionName, transitionProperties) => {
		const _deferred = $q.defer();
		ConsultationService.getConsultationStateAndStatusForCustomer({
			serviceProviderId: transitionProperties.toParams.serviceProviderId,
			customerToken: transitionProperties.toParams.customerToken,
			consultationId: transitionProperties.toParams.consultationId,
		})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) {
					const {consultation_status_system_name: status, consultation_expired, proposal_id} = data.payLoad;
					const _isPublic = NavService.isStatePublic(transitionProperties.toState.name);

					if (publicPermissionsObj[permissionName].requiredStatus === status && _isPublic && !consultation_expired) {
						_deferred.resolve();
					} else {
						let toStateName;
						switch (status) {
							case 'draft':
								toStateName = 'view.unavailable';
								break;
							case 'accepted':
								toStateName = 'view.accepted';
								transitionProperties.toParams.proposalId = proposal_id;
								break;
							case 'presented':
								toStateName = consultation_expired ? 'view.expired' : 'view.consultation';
								break;
							case 'lost':
								toStateName = 'view.lost';
								break;
							default:
								break;
						}

						_deferred.reject({
							state: toStateName,
							params: transitionProperties.toParams,
						});
					}
				}
				if (data?.error) _deferred.reject(data?.error);
			})
			.catch(err => _deferred.reject(err));
		return _deferred.promise;
	});
	// #endregion

	// #region PRESENTATION PRIVATE
	const privatePresentationObj = {
		isPresentedAndPrivate: {
			requiredStatus: 'presented',
		},
		isAcceptedAndPrivate: {
			requiredStatus: 'accepted',
		},
		isLostAndPrivate: {
			requiredStatus: 'lost',
		},
	};
	const privatePresentationArr = Object.keys(privatePresentationObj);
	PermPermissionStore.defineManyPermissions(privatePresentationArr, (pName, transitionProperties) => {
		const _user = $cookies.getObject('OCAUS');
		const _deferred = $q.defer();
		ConsultationService.postValidateConsultationStateAndStatus({
			consultationId: transitionProperties.toParams.consultationId,
			serviceProviderId: _user.service_provider_id,
			userId: _user.id,
		})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) {
					const {consultation_status_system_name: status} = data.payLoad;
					let toStateName;
					const _isPublic = NavService.isStatePublic(transitionProperties.toState.name);

					if (privatePresentationObj[pName].requiredStatus === status && !_isPublic) {
						_deferred.resolve();
					} else {
						// fixme: this is necessary to get the customer_location_id, this piece of data should be resolved from summary to avoid carrying data via sessionStorage.
						const storedConsultation = StorageService.get('consultation') || {};
						switch (status) {
							case 'draft':
								StorageService.set(
									'consultation',
									Object.assign(storedConsultation, {
										consultation_id: transitionProperties.toParams.consultationId,
										consultation_current_client_state: toStateName,
										consultation_client_state: toStateName,
										status: 'draft',
									})
								);
								toStateName = 'proposal.summary';
								break;

							case 'accepted':
								toStateName = 'preview.accepted';
								break;

							case 'presented':
								toStateName = 'preview.consultation';
								break;

							case 'lost':
								toStateName = 'preview.lost';
								break;

							default:
								break;
						}

						_deferred.reject({
							state: toStateName,
							params: transitionProperties.toParams,
						});
					}
				}
				if (data?.error) _deferred.reject(data?.error);
			})
			.catch(err => _deferred.reject(err));
		return _deferred.promise;
	});
};
permissionsDefinitions.$inject = ['$injector'];
export default permissionsDefinitions;
