export class StateSettings {
	constructor($q, $log, StorageService, $state, ENV) {
		'ngInject';
		this.key = `${ENV}_pref`;
		this.q = $q;
		this.log = $log;
		this.storageService = StorageService;
		this.state = $state;
		this.prefix = '';
	}

	/**
	 * Set key prefix in order of having UI settings by view and consultation
	 * @param {string} id
	 * @memberof StateSettings
	 */
	setPrefix(id) {
		this.prefix = id || '';
	}

	/**
	 * @param {Array | undefined} keys
	 * @returns Object
	 * @memberof StateSettings
	 */
	get(keys) {
		return this.q.when(this.getSync(keys));
	}

	/**
	 * @param {Array | undefined} keys
	 * @returns  Object of key value config: {type: 'custom'}
	 * @memberof StateSettings
	 */
	getSync(keys) {
		if (keys && !Array.isArray(keys)) {
			this.log.error('keys param should be an Array');
			return {};
		}
		const _storeKey = this.genUiKey(this.state.current.name);
		const _storeData = this.storageService.get(this.key) || {};

		if (Object.prototype.hasOwnProperty.call(_storeData, _storeKey)) {
			if (keys) {
				const _keysData = Object.keys(_storeData[_storeKey]).reduce((acc, key) => {
					if (keys.indexOf(key) !== -1) acc[key] = _storeData[_storeKey][key];
					return acc;
				}, {});
				return _keysData;
			}
			return _storeData[_storeKey];
		}
		return {};
	}

	/**
	 * @param {Array} params [{key: string, value: any}]
	 * @returns Promise Object
	 * @memberof StateSettings
	 */
	set(params) {
		const _deferred = this.q.defer();
		const _setRes = this.setSync(params);
		if (_setRes) {
			_deferred.resolve(_setRes);
		} else {
			_deferred.reject({
				error: 'Unable to store ui settings in local storage',
			});
		}
		return _deferred.promise;
	}

	/**
	 * @param {Array} params [{key: string, value: any}]
	 * @returns Object
	 * @memberof StateSettings
	 */
	setSync(params) {
		const _stateKey = this.genUiKey(this.state.current.name);
		const _storedObj = this.storageService.get(this.key) || {};

		if (!Object.prototype.hasOwnProperty.call(_storedObj, _stateKey)) {
			_storedObj[_stateKey] = {};
		}

		params.forEach(attr => {
			_storedObj[_stateKey][attr.key] = attr.value;
		});

		if (this.storageService.set(this.key, _storedObj)) {
			return _storedObj[_stateKey];
		} else {
			return false;
		}
	}

	genUiKey(stateName) {
		// the key is based on state name plus a prefix (like consultation id)
		const _keyByState = stateName.replace('.', '_');
		const _prefix = this.prefix ? `${this.prefix}_` : '';
		return `${_prefix}${_keyByState}`;
	}

	/**
	 * @param {Array} keys
	 * @returns {}
	 * @memberof StateSettings
	 */
	remove(keys) {
		if (keys && !Array.isArray(keys)) {
			this.log.error('keys param should be an Array');
			return {};
		}
		const _storeKey = this.genUiKey(this.state.current.name);
		const _allStore = this.storageService.get(this.key) || {};

		if (Object.prototype.hasOwnProperty.call(_allStore, _storeKey)) {
			// loop over store[state] object keys
			const _newObj = Object.keys(_allStore[_storeKey])
				.filter(key => keys.indexOf(key) === -1)
				.reduce((acc, curr) => {
					acc[curr] = _allStore[_storeKey][curr];
					return acc;
				}, {});
			// override stored object
			_allStore[_storeKey] = _newObj;

			// store back
			if (this.storageService.set(this.key, _allStore)) {
				return _allStore[_storeKey];
			}
		}

		return false;
	}
}

let compMod = angular.module('mod.svc.StateSettingsService', []).service('StateSettingsService', StateSettings);
export default compMod = compMod.name;
