const navStates = [
	{
		label: 'Navigator',
		name: 'navigator',
		active: false,
		disabled: true,
		visible: false,
	},
	{
		label: 'Customer',
		name: 'proposal.customer',
		active: false,
		disabled: true,
		visible: true,
	},
	{
		label: 'Survey',
		name: 'proposal.survey',
		active: false,
		disabled: true,
		visible: true,
	},
	{
		label: 'Proposals',
		name: 'proposal.equipment',
		active: false,
		disabled: true,
		visible: true,
	},
	{
		label: 'Accessories',
		name: 'proposal.accessories',
		active: false,
		disabled: true,
		visible: true,
	},
	{
		label: 'Installation',
		name: 'proposal.installation',
		active: false,
		disabled: true,
		visible: true,
	},
	{
		label: 'Summary',
		name: 'proposal.summary',
		active: false,
		disabled: true,
		visible: true,
	},
];

export default navStates;
