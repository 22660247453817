/** @ngInject */
function MainController($rootScope, HOST_URL, API) {
	const $ctrl = this;
	// #region bind vars
	$ctrl.appHost = HOST_URL;
	$ctrl.apiHost = API;
	$ctrl.pageTitle = $rootScope.pageTitle;
	// #endregion
}

let compMod = angular.module('mod.cmp.MainController', []).controller('MainController', MainController);
export default compMod = compMod.name;
