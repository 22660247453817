export const AUTH_KEYS = {
	ocaKey: 'auth0-flow',
	tokenKey: 'OCATK',
	userKey: 'OCAUS',
	nameSpace: 'https://app.oncallair.com',
	bufferReq: true,
};
export const INIT_FLAGS = {
	AUTH0_FLOW: 'auth0-flow',
	ALERT_TIME_NOT_SYNC: 'alert_time_not_sync',
};
let compMod = angular
	.module('module.constants', [])
	.constant(
		'CONNECTIVITY_ERROR',
		"There seems to be a problem with your device's connection. Please try moving to a location with better signal."
	)
	.constant('ROLE_ALIASES', {
		admin: 'northrop',
		super: 'raptor',
		consultant: 'boeing',
	})
	.constant('OCA_NUMBER', '1-888-808-6231')
	.constant(
		'DEFAULT_IMG',
		'https://resource.carrierenterprise.com/is/image/Watscocom/no?wid=140&hei=140&fmt=png-alpha&defaultImage=Eng_coming_soon'
	)
	.value('colorVars', {
		brandPrimary: '#1b80ac',
		brandSecondary: '#ffffff',
	})
	.constant('TYPES_WITH_FURNACE', ['straight_cool_plus_furnace', 'gas_furnace', 'hybrid_heat_pump'])
	.constant('FURNACE_CONFIG_OPTS', ['upflow', 'downflow', 'horizontal'])
	.constant('logSettings', {
		testMode: false,
		envToLog: ['staging', 'production'],
		levelsToLog: ['error', 'warn'],
	})
	.constant('GENERIC_EMAIL', 'generic_email_to_generate_viewing_token@oncallair.com')
	.value('FINANCING_OPTION_TYPES', {
		standard: 'standard',
		textOnly: 'textOnly',
		c4c: 'c4c',
	})
	.constant('REGEX', {
		email: /^\S+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/,
		// us phones
		phone: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
		ZIP_CA_US: /^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] *\d[A-Z]\d)$/i,
		// numbers with optional two decimals
		financial: /\d+(?:\.?)(?:\d){0,2}/,
		url: /^https?:\/\/.?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_+.~#?&//=]*/,
	})
	.constant('CAPABILITIES', {
		adjust_total_investment: 'adjust_total_investment',
		manage_lost_status: 'manage_lost_status',
		view_all_consultations: 'view_all_consultations',
	})
	.constant('DATA_DOG_CONFIG', {
		development: {
			applicationId: 'df77a28c-359b-47ef-9866-80798e30fa5f',
			clientToken: 'pub577290c512d8edde4b60c071928d2565',
			service: 'oca-client-development',
		},
		staging: {
			applicationId: '518491aa-243b-4c5f-80c6-32c61ac8120f',
			clientToken: 'pub9a7d5ce48465c0c4f314ed01d103608b',
			service: 'oca-client-staging',
		},
		production: {
			applicationId: '8f3705c6-bc52-4659-bc2b-9d2336efea61',
			clientToken: 'pub97cf20c544494b01272edcee25895e7b',
			service: 'oca-client-production',
		},
	})
	.constant('AUTH_KEYS', AUTH_KEYS)
	.constant('INIT_FLAGS', INIT_FLAGS)
	.constant('GOOGLE_CONFIG', {
		mapsUrl: 'https://maps.googleapis.com/maps/api/js',
	});
export default compMod = compMod.name;
