export class NotifySubscribe {
	constructor($rootScope) {
		'ngInject';
		this.rootScope = $rootScope;
	}
	notify(event, data) {
		this.rootScope.$emit(event, data);
	}

	subscribe(scope, eventName, callback) {
		var handler = this.rootScope.$on(eventName, callback);
		scope.$on('$destroy', handler);
	}
}

let svcMod = angular.module('mod.svc.NotifySubscribe', []).service('NotifyingService', NotifySubscribe);
export default svcMod = svcMod.name;
