import ModalAlertTpl from './modal-alert.html';
//
function compController() {
	const $ctrl = this;

	$ctrl.$onInit = () => {
		$ctrl.data = $ctrl.resolve.data;
		$ctrl.dismiss = dismiss;
		$ctrl.close = close;
	};

	function close() {
		$ctrl.modalInstance.close('close');
	}

	function dismiss() {
		$ctrl.modalInstance.dismiss('dismiss');
	}
}

const compConfig = {
	template: ModalAlertTpl,
	controller: compController,
	bindings: {
		modalInstance: '<',
		resolve: '<',
	},
};

let compMod = angular.module('mod.cmp.modalAlert', []).component('modalAlert', compConfig);
export default compMod = compMod.name;
