export class Discount {
	constructor($http, API, PUBLIC_KEY) {
		'ngInject';
		this.http = $http;
		this.gate = '/discount';
		this.API = API;
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	getProposalDiscountById(params, cache = false) {
		return this.http.get(`${this.API}/v1${this.gate}/proposalDiscountById`, {params, cache});
	}

	postAddDiscountToProposal(params) {
		return this.http.post(`${this.API}/v1${this.gate}/addDiscountToProposal`, params);
	}

	postCreateProposalDiscount(params) {
		return this.http.post(`${this.API}/v1${this.gate}/createProposalDiscount`, params);
	}

	postRemoveProposalDiscount(params) {
		return this.http.post(`${this.API}/v1${this.gate}/removeProposalDiscount`, params);
	}

	postUpdateProposalDiscount(params) {
		return this.http.post(`${this.API}/v1${this.gate}/updateProposalDiscount`, params);
	}

	getDiscountById(params, cache = false) {
		return this.http.get(`${this.API}/v1${this.gate}/discountById`, {params, cache});
	}

	getAllDiscountsByServiceProvider(params, cache = false) {
		return this.http.get(`${this.API}/v1${this.gate}/allDiscountsByServiceProvider`, {params, cache});
	}

	postCreateDiscount(params) {
		return this.http.post(`${this.API}/v1${this.gate}/createDiscount`, params);
	}

	postUpdateDiscount(params) {
		return this.http.post(`${this.API}/v1${this.gate}/updateDiscount`, params);
	}

	postDeleteDiscount(params) {
		return this.http.post(`${this.API}/v1${this.gate}/removeDiscount`, params);
	}

	postUpdatePublicationStatus(params) {
		return this.http.post(`${this.API}/v1${this.gate}/updatePublicationStatus`, params);
	}

	getAllDiscountValueTypes(params, cache = false) {
		return this.http.get(`${this.API}/v1${this.gate}/allDiscountValueTypes`, {params, cache});
	}

	postManageDiscountInclusion(params) {
		return this.http.post(`${this.API}/v1${this.gate}/manageDiscountInclusion`, params);
	}

	postManageDiscountInclusionByCustomer(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.API}/v1${this.gate}/manageDiscountInclusionByCustomer`, params, config);
	}
}

let compMod = angular.module('mod.svc.DiscountService', []).service('DiscountService', Discount);
export default compMod = compMod.name;
