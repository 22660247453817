export class TextUtilsService {
	constructor(He, $log) {
		'ngInject';
		this.log = $log;
		this.he = He;
	}

	/**
	 * remove HTML tags from content.
	 * @param {string} content
	 * @param {string} html tag
	 * @returns {string} html content
	 */
	stripHtml(content, element) {
		var _regex;
		var _subst = '';
		switch (element) {
			case 'containers':
				// remove all div tags with any attr
				_regex = /<\s*(div|section|article).*?>|<\s?\/\s*(div|section|article)\s*.*?>/gim;
				break;
			case 'div':
				// remove all div tags with any attr
				_regex = /<\s*div.*?>|<\s*\/\s*div\s*.*?>/gim;
				break;
			case 'style:attr':
				// remove all div tags with any attr
				_regex = /\s?style="[a-z0-9\-:\s;\(\),&.*]+"/gim; // eslint-disable-line
				break;
			case 'br':
				// replace consecutive br with one
				_regex = /(<\s*br.*?\/?>)+/gim;
				break;
			case 'common':
				// remove all spaces
				_regex = /(&nbsp;|\s?class="[\w\d-]+")+/gim;
				break;
			default:
				// remove all html tags
				_regex = /<[^>]+>|&nbsp;&nbsp;/gim;
				break;
		}
		return content ? String(content).replace(_regex, _subst) : '';
	}

	/**
	 * remove HTML tags from content.
	 * @param {string} content
	 * @returns {string} capitalize cased
	 */
	toCapitalizeCase(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	/**
	 * change text casing to sentence.
	 * @param {string} str
	 * @returns {string} camel cased
	 */
	toCamelCase(str) {
		return str
			.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index == 0 ? word.toLowerCase() : word.toUpperCase()))
			.replace(/\s+/g, '');
	}

	/**
	 * change text casing to sentence.
	 * @param {string} str
	 * @returns {string} sentence cased
	 */
	toSentenceCase(str) {
		return str.replace(/\w+/, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
	}

	formatToPhone(phone) {
		if (!phone || !RegExp('^[0-9]').test(phone)) {
			return false;
		}
		const value = phone.toString().trim().replace(/^\+/, '');
		const x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
		return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
	}

	/**
	 * Encode html entities https://github.com/mathiasbynens/he
	 * encodes any symbols that aren’t printable ASCII symbols
	 * @param {string} str
	 * @returns String - encoded string
	 */
	encodeHtmlEntities(str) {
		if (!str) return str;
		return this.he.encode(str, {
			allowUnsafeSymbols: true,
			useNamedReferences: true,
		});
	}

	/**
	 * Decode html entities https://github.com/mathiasbynens/he
	 * @param {string} str
	 * @returns String - decoded string with html entities
	 */
	decodeHtmlEntities(str) {
		if (!str) return str;
		return this.he.decode(str);
	}

	/**
	 * @param {string} str
	 * @returns String - set anchor target attribute to _blank
	 */
	setLinkTargetBlank(str) {
		let _result = angular.copy(str);
		if (_result) {
			const _regex = /<\s*a[\s]+(?:[^>]+)>(?:.(?!<\/a>))*.<\s*\/a>/g;
			const _matches = str.match(_regex);
			if (_matches) {
				for (let i = 0; i < _matches.length; i++) {
					const _original = _matches[i];
					const _copy = angular.element(_matches[i])[0];
					_copy.target = '_blank';
					_result = _result.replace(_original, _copy.outerHTML);
				}
			}
		}
		return _result;
	}

	/**
	 * Return a capacity unit name
	 * @param {Array} name
	 * @returns String
	 */
	componentTypeUnit(name) {
		let unit;
		switch (name) {
			case 'AC':
			case 'Air Handlers':
			case 'Evap Coils':
			case 'Ductless':
			case 'Small Packaged':
			case 'HP':
				unit = 'Ton';
				break;
			case 'Furnaces':
			case 'Boiler':
				unit = 'BTU';
				break;
			case 'Water Heater':
				unit = 'Gal';
				break;
			default:
				unit = 'Ton';
				break;
		}
		return unit;
	}
}

let compMod = angular.module('mod.svc.TextUtilsService', []).service('TextUtilsService', TextUtilsService);
export default compMod = compMod.name;
