export class RoutesResolve {
	constructor($http, $q, API, $log, AuthService, StorageService, FeatureFlagsSvc, dateFns, PUBLIC_KEY) {
		'ngInject';
		this.$http = $http;
		this.$q = $q;
		this.$log = $log;
		this.dateFns = dateFns;
		this.AuthService = AuthService;
		this.StorageService = StorageService;
		this.FeatureFlagsSvc = FeatureFlagsSvc;
		this.api = API;
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	getUser() {
		const _user = this.AuthService.currentUser();
		if (!_user || !_user?.id || !_user?.service_provider_id) {
			this.$log.log('User not logged in');
			return false;
		}
		return _user;
	}

	getFlagsCapabilitiesLogAccess(params) {
		if (!this.getUser()) return this.$q.reject({display: 'User must be logged in'});

		const getFlags = this.getProviderFlags(params);
		const getCapabilities = this.getUserCapabilities(params);
		const logAccess = this.postLastUserAccess(params);
		return this.$q.all([getFlags, getCapabilities, logAccess]);
	}

	getProviderFlags(params) {
		const _user = this.getUser();
		if (!this.getUser()) return this.$q.reject({display: 'User must be logged in'});

		const _header = {'X-State-Name': params?.fromState || ''};
		const _errMsg = {display: 'Error getting features flag'};
		const _dfr = this.$q.defer();
		const _params = {serviceProviderId: _user?.service_provider_id, userId: _user?.id};
		this.$http
			.get(`${this.api}/serviceProvider/featureflags`, {params: _params, headers: _header})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) {
					this.FeatureFlagsSvc.addFlags(data.payLoad);
					_dfr.resolve(data.payLoad);
				}
				if (data?.error) _dfr.reject(Object.assign(data.error, _errMsg));
			})
			.catch(resObj => {
				this.$log.error(resObj);
				_dfr.reject(Object.assign({fromState: params.fromState}, _errMsg));
			});
		return _dfr.promise;
	}

	getProviderFlagsPublic(params) {
		if (!params || !params?.serviceProviderId || !params?.consultationId || !params?.customerToken) {
			return this.$q.reject({display: 'Params are required'});
		}

		const _header = {'X-State-Name': params?.fromState || '', Auth: this.PUBLIC_KEY};
		const _errMsg = {display: 'Error getting features flag'};
		const _dfr = this.$q.defer();
		const _params = {
			serviceProviderId: Number(params?.serviceProviderId),
			consultationId: Number(params?.consultationId),
			customerToken: params?.customerToken,
		};
		this.$http
			.get(`${this.api}/serviceProvider/featureFlagsForCustomer`, {params: _params, headers: _header})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) {
					this.FeatureFlagsSvc.addFlags(data.payLoad);
					_dfr.resolve(data.payLoad);
				}
				if (data?.error) _dfr.reject(Object.assign(data.error, _errMsg));
			})
			.catch(resObj => {
				this.$log.error(resObj);
				_dfr.reject(Object.assign({fromState: params.fromState}, _errMsg));
			});
		return _dfr.promise;
	}

	getUserCapabilities(params) {
		const _user = this.getUser();
		if (!_user || !_user?.id || !_user?.service_provider_id) return this.$q.reject({display: 'User must be logged in'});

		const _dfr = this.$q.defer();
		const _errMsg = {display: 'Error getting user capabilities'};
		const _header = {'X-State-Name': params?.fromState || ''};
		const _params = {serviceProviderId: _user.service_provider_id, userId: _user.id};
		this.$http
			.get(`${this.api}/serviceProviderUser/capabilities`, {params: _params, headers: _header})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) {
					this.AuthService.setUserCapabilities(data.payLoad);
					_dfr.resolve(data.payLoad);
				}
				if (data?.error) _dfr.reject(Object.assign(data.error, _errMsg));
			})
			.catch(resObj => {
				this.$log.error(resObj);
				_dfr.reject(Object.assign({fromState: params.fromState}, _errMsg));
			});
		return _dfr.promise;
	}

	postLastUserAccess(params) {
		const _user = this.getUser();
		if (!_user) return this.$q.reject({display: 'User must be logged in'});

		const _errMsg = {display: 'Error logging last access'};
		const _header = {'X-State-Name': params?.fromState || ''};
		const _dfr = this.$q.defer();
		// only log event with min interval of 60 min, else resolve silently
		const currentAccess = Date.now();
		const lastAccessed = this.StorageService.get(`last-accessed`);
		const minutesSince = this.dateFns.differenceInMinutes(currentAccess, lastAccessed);
		if (!lastAccessed || !minutesSince || minutesSince >= 60) {
			const _params = {
				serviceProviderId: _user.service_provider_id,
				userId: _user.id,
			};
			this.$http
				.post(`${this.api}/v1/serviceProviderUser/trackLastUserAccess`, _params, {headers: _header})
				.then(resObj => {
					const {data} = resObj;
					if (data?.success) {
						this.StorageService.set(`last-accessed`, currentAccess);
						_dfr.resolve(data.payLoad);
					}
					if (data?.error) _dfr.reject(Object.assign(data.error, _errMsg));
				})
				.catch(resObj => {
					this.$log.error(resObj);
					_dfr.reject(Object.assign({fromState: params.fromState}, _errMsg));
				});
		} else {
			_dfr.resolve(minutesSince);
		}
		return _dfr.promise;
	}
}
//
let svcMod = angular.module('mod.svc.RoutesResolve', []).service('RoutesResolveSvc', RoutesResolve);
export default svcMod = svcMod.name;
