export class Customer {
	constructor($http, API) {
		'ngInject';
		this.http = $http;
		this.gate = '/customer';
		this.API = API;
	}

	getCustomerDetails(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/customerDetail`, {params, cache});
	}

	getCustomerLocationDetails(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/customerLocationDetail`, {params, cache});
	}

	getCurrentSystemDetails(params, cache = false) {
		return this.http.get(`${this.API}${this.gate}/currentSystemDetails`, {params, cache});
	}

	createCustomer(params) {
		return this.http.post(`${this.API}${this.gate}/createCustomer`, params);
	}

	updateCustomer(params) {
		return this.http.post(`${this.API}/v2${this.gate}/updateCustomer`, params);
	}

	createCustomerLocation(params) {
		return this.http.post(`${this.API}${this.gate}/createCustomerLocation`, params);
	}

	updateCustomerLocation(params) {
		return this.http.post(`${this.API}/v2${this.gate}/updateCustomerLocation`, params);
	}

	saveCurrentSystemPreferences(params) {
		return this.http.post(`${this.API}/v2${this.gate}/saveCurrentSystemPreferences`, params);
	}
}

let compMod = angular.module('mod.svc.CustomerService', []).service('CustomerService', Customer);
export default compMod = compMod.name;
