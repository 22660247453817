import './splash-loader.scss';
import SplashLoaderTpl from './splash-loader.html';

class SplashLoaderController {
	/** @ngInject */
	constructor($timeout) {
		this.$timeout = $timeout;
		this.fadeSplash = false;
		this.showSplash = true;
	}

	$onInit() {}

	$onChanges(changesObj) {
		if (changesObj.hideSplash.currentValue === false) {
			this.initHiding();
		}
	}

	initHiding() {
		this.$timeout(() => {
			this.doFadeSplash();
		}, 1000);
	}

	doFadeSplash() {
		this.fadeSplash = true;
		this.$timeout(() => {
			this.showSplash = false;
		}, 2000);
	}
}

const SplashLoader = {
	template: SplashLoaderTpl,
	controller: SplashLoaderController,
	bindings: {
		hideSplash: '<',
	},
};

export default SplashLoader;
