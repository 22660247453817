import './render-alert.scss';
import RenderAlertTpl from './render-alert.html';
//
const compConfig = {
	template: RenderAlertTpl,
	controller: RenderAlertsController,
	bindings: {
		alertType: '<',
		alertMsg: '<',
		alertShow: '<',
		isDismissible: '<?',
	},
};
/** @ngInject */
function RenderAlertsController($timeout) {
	const $ctrl = this;
	const _inClass = ' in';
	let _prevType;
	$ctrl.$onInit = () => {
		$ctrl.alertClasses = 'alert-' + $ctrl.alertType;
		_prevType = $ctrl.alertType;
		$ctrl.close = close;
		show();
	};
	$ctrl.$onChanges = changesObj => {
		if (changesObj.alertType && !changesObj.alertType.isFirstChange()) {
			$ctrl.alertClasses = $ctrl.alertClasses.replace(_prevType, changesObj.alertType.currentValue);
		}
	};

	function close() {
		$timeout(() => {
			$ctrl.alertClasses = $ctrl.alertClasses.replace(_inClass, '');
		}, 100);
	}

	function show() {
		$timeout(() => {
			$ctrl.alertClasses += _inClass;
		}, 100);
	}
}

let compMod = angular.module('mod.cmp.renderAlert', []).component('renderAlert', compConfig);
export default compMod = compMod.name;
