export class ModalHelperService {
	constructor($uibModal, OCA_NUMBER) {
		'ngInject';
		this.uibModal = $uibModal;
		this.phone = OCA_NUMBER;
	}

	errorModal(title = null, message = null, previousPageBackMode = false, onlyRefresh = false, size = 'sm') {
		const errorMessage = message
			? `<p class="text-center">${message}</p>`
			: `<p class="text-center">Please try again or contact us at <span class="text-nowrap">${this.phone}</span>.</p>`;
		const errorTitle = title ? title : 'Something went wrong';
		return this.uibModal.open({
			backdrop: 'static',
			keyboard: false,
			component: 'modalError',
			size,
			windowClass: 'modal-error',
			resolve: {
				data() {
					return {
						errorTitle,
						errorMessage,
						previousPageBackMode,
						onlyRefresh,
					};
				},
			},
		});
	}

	/**
	 * Create modal instance
	 * @param {string} size modal size
	 * @param {Boolean} keyboard
	 * @param {string} title
	 * @param {string} message
	 * @param {Object|Boolean} dismissBtn
	 * @param {Object|Boolean} closeBtn
	 * @param {Boolean} backdrop
	 */
	alertModal(
		size = 'sm',
		keyboard = true,
		title = null,
		message = null,
		dismissBtn = false,
		closeBtn = false,
		backdrop = true
	) {
		// todo: validate that dismissBtn & closeBtn are not null
		const _btn = 'btn';
		const dismiss =
			dismissBtn && !dismissBtn.classes
				? Object.assign(dismissBtn, {classes: `${_btn} btn-outline-primary`})
				: Object.assign(dismissBtn, {classes: `${_btn} ${dismissBtn.classes}`});
		const close =
			closeBtn && !closeBtn.classes
				? Object.assign(closeBtn, {classes: `${_btn} btn-primary`})
				: Object.assign(closeBtn, {classes: `${_btn} ${closeBtn.classes}`});
		return this.uibModal.open({
			keyboard,
			component: 'modalAlert',
			windowClass: 'modal-alert',
			size,
			backdrop,
			resolve: {
				data: () => ({title, message, dismiss, close}),
			},
		});
	}
}

let compMod = angular.module('mod.svc.modalHelperService', []).service('ModalHelperService', ModalHelperService);
export default compMod = compMod.name;
