export class ServiceProviderUser {
	constructor($http, API, PUBLIC_KEY) {
		'ngInject';
		this.http = $http;
		this.path = `${API}/v1/serviceProviderUser`;
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	postLogin(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/login`, params, config);
	}

	getAllUsersByProvider(params, cache = false) {
		return this.http.get(`${this.path}/allUsersByProvider`, {params, cache});
	}

	doLogout(params) {
		return this.http.post(`${this.path}/logout`, params);
	}

	resetPassword(params) {
		return this.http.post(`${this.path}/resetpassword`, params);
	}

	getForgotPasswordForEmail(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/forgotPasswordForEmail`, {params, cache, headers});
	}

	getUserDetailsByForgotToken(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/userDetailsByForgotToken`, {params, cache, headers});
	}

	changePasswordWithForgotToken(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/changePasswordWithForgotToken`, params, config);
	}

	getAllUsersByServiceProvider(params, cache = false) {
		return this.http.get(`${this.path}/allUsersByServiceProvider`, {params, cache});
	}

	loginServiceProvider(params) {
		return this.http.post(`${this.path}/loginServiceProvider`, params);
	}

	getUsersByServiceProvider(params, cache = false) {
		return this.http.get(`${this.path}/usersByServiceProvider`, {params, cache});
	}

	getCapabilities(params, cache = false) {
		return this.http.get(`${this.path}/capabilities`, {params, cache});
	}

	getUserDetailsById(params, cache = false) {
		return this.http.get(`${this.path}/userDetailsById`, {params, cache});
	}

	enableUser(params) {
		return this.http.post(`${this.path}/enable`, params);
	}

	disableUser(params) {
		return this.http.post(`${this.path}/disable`, params);
	}

	updateUser(params) {
		return this.http.post(`${this.path}/updateUser`, params);
	}

	createUser(params) {
		return this.http.post(`${this.path}/createUser`, params);
	}

	getAllFeatures(params, cache = false) {
		return this.http.get(`${this.path}/allFeatures`, {params, cache});
	}

	postAssignCommissionTierToUser(params) {
		return this.http.post(`${this.path}/assignCommissionTierToUser`, params);
	}

	postUnAssignCommissionTierToUser(params) {
		return this.http.post(`${this.path}/unassignCommissionTierToUser`, params);
	}

	getAllPermissions(params, cache = false) {
		return this.http.get(`${this.path}/allPermissions`, {params, cache});
	}

	getDetailsByToken(params, cache = true) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/detailsByToken`, {params, cache, headers});
	}

	postSavePassword(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/savePassword`, params, config);
	}

	getFeatures(params, cache = false) {
		return this.http.get(`${this.path}/features`, {params, cache});
	}
}

let compMod = angular
	.module('mod.svc.ServiceProviderUser', [])
	.service('ServiceProviderUserService', ServiceProviderUser);
export default compMod = compMod.name;
