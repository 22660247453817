const themedStates = [
	'preview.consultation',
	'preview.proposal',
	'preview.accepted',
	'preview.lost',
	'preview.inspector',
	'view.consultation',
	'view.proposal',
	'view.confirmation',
	'view.accepted',
	'view.inspector',
	'view.lost',
	'view.expired',
	'view.unavailable',
];

export default themedStates;
