import Color from './ColorsService.js';
import Solver from './ColorSolverService.js';

export class Theming {
	constructor(DOMHelpersSvc, ColorService, ColorSolverService) {
		'ngInject';
		this.colorService = ColorService;
		this.colorSolverService = ColorSolverService;
		this.domHelpersSvc = DOMHelpersSvc;
	}

	/**
	 * @param {string} color hexadecimal value
	 * @param {Integer} percent (positives lighter, negative darker)
	 * @returns color variant (hexadecimal value)
	 */
	shadeColor(color, percent) {
		const f = parseInt(color.slice(1), 16);
		const t = percent < 0 ? 0 : 255;
		const p = percent < 0 ? percent * -1 : percent;
		const R = f >> 16;
		const G = (f >> 8) & 0x00ff;
		const B = f & 0x0000ff;
		return `#${(
			0x1000000 +
			(Math.round((t - R) * p) + R) * 0x10000 +
			(Math.round((t - G) * p) + G) * 0x100 +
			(Math.round((t - B) * p) + B)
		)
			.toString(16)
			.slice(1)}`;
	}

	/**
	 * @param {string} id
	 * @returns Boolean - Where styleSheet#<rule> is empty
	 */
	removeSkin(id) {
		const elem = this.domHelpersSvc.getById(id);
		return elem ? elem.parentNode.removeChild(elem) : true;
	}

	/**
	 * Remove style DOM element by id
	 * @returns void
	 * @memberof Theming
	 */
	resetSkin() {
		if (!this.existSkin()) return true;
		return this.removeSkin('providerTheme');
	}

	generateSkin(opts) {
		if (this.existSkin()) return true;

		const rgb = this.hexToRgb(opts.brandPrimary);
		this.colorService.set(rgb[0], rgb[1], rgb[2]);
		this.colorSolverService.setTarget(this.colorService.getAsRGB());
		this.colorSolverService.setTargetHSL(this.colorService.hsl());
		const result = this.colorSolverService.solve();

		this.addStylesheetRules([
			[
				'#mainHeader div .item, #customerHeader #headerNav .item, .btn-outline-primary:hover, #callUs span a',
				['color', '#ffffff', true],
			],
			[
				'.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active',
				['background-color', opts.brandPrimary, true],
			],
			// SECONDARY
			// color secondary
			[
				'h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .text-secondary, .nav-tabs > li.active a, .nav-tabs > li a:hover, .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus, .page-title',
				['color', opts.brandSecondary, true],
			],
			// background secondary
			[
				'.bg-secondary, .btn-secondary, .btn-secondary[disabled], .btn-secondary[disabled]:hover',
				['background-color', opts.brandSecondary, true],
			],
			// background secondary dark
			[
				'.btn-secondary:hover:not(.selected), .btn-secondary:focus, .btn-secondary:active:hover, .btn-secondary:active:focus',
				['background-color', this.shadeColor(opts.brandSecondary, -0.3), true],
			],
			// border secondary
			[
				'.bd-secondary, .nav-tabs > li a, .nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:focus',
				['border-color', opts.brandSecondary],
			],
			// border secondary light
			[
				'.two-column-divider, .summary .options ul.financing, .summary .options ul.title',
				['border-color', this.shadeColor(opts.brandSecondary, 0.3)],
			],
			// border secondary dark
			[
				'.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active:hover, .btn-secondary:active:focus',
				['border-color', this.shadeColor(opts.brandSecondary, -0.5)],
			],
			// PRIMARY
			// color primary
			[
				'a:not(.btn), .text-primary, .section-block .card-title.text-primary, .btn-outline-primary, .btn-clean-primary',
				['color', opts.brandPrimary, true],
			],
			['.btn-outline-primary:hover', ['color', '#ffffff', true]],
			['.btn:focus, .btn.active:focus', ['outline-color', opts.brandPrimary]],
			// color primary dark
			[
				'a:not(.btn):hover, a:focus, .btn-clean-primary:hover, .btn-clean-primary:focus, .btn-clean-primary:active:hover, .btn-clean-primary:active:focus, .btn-outline-primary:focus, .btn-outline-primary:active:hover, .btn-outline-primary:active:focus',
				['color', this.shadeColor(opts.brandPrimary, -0.3), true],
			],
			// background primary
			[
				'.btn-primary, .btn-primary[disabled], .btn-primary[disabled]:hover, .bg-primary, .bundle-box .bundle-hover:hover:not(.unclickable)',
				['background-color', opts.brandPrimary, true],
			],
			// background primary dark
			[
				'.btn-primary:hover, .btn-primary:focus, .btn-primary:active:hover, .btn-primary:active:focus',
				['background-color', this.shadeColor(opts.brandPrimary, -0.3), true],
			],
			// border primary
			[
				'.bd-primary, .btn-outline-primary, .form-control:focus, .is-loading.btn-clean-primary:after, .navbar nav li .btn.btn-clean-primary',
				['border-color', opts.brandPrimary, true],
			],
			['.is-loading.spinner-primary:after', ['border-color', opts.brandPrimary]],
			// border primary dark
			[
				'.btn-primary, .btn-primary:hover, .btn-primary:focus, .btn-primary:active:hover, .btn-primary:active:focus, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-outline, .btn-outline:hover, .btn-outline:focus, btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:active:focus, .btn-outline-primary[disabled]:hover, .bd-primary-dark',
				['border-color', this.shadeColor(opts.brandPrimary, -0.35), true],
			],
			[
				'.btn-outline-primary:not(:disabled):not(.disabled):active:focus',
				['box-shadow', '0 0 0 0.2rem rgba(' + opts.brandPrimary + ', 0.5)', true],
			],
			// shadow primary
			[
				'form-control:focus',
				['box-shadow', 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(' + opts.brandPrimary + ', 0.6)'],
			],
			['.icon.text-primary', ['filter', result.filter]],
		]);

		return true;
	}

	addStylesheetRules(rules) {
		const styleEl = this.domHelpersSvc.createElement('style');
		styleEl.setAttribute('media', 'screen');
		styleEl.setAttribute('type', 'text/css');
		styleEl.setAttribute('id', 'providerTheme');

		// Append style element to head
		this.domHelpersSvc.appendChild('head', styleEl);

		// Grab style sheet
		var styleSheet = styleEl.sheet;

		for (let i = 0, rl = rules.length; i < rl; i++) {
			var j = 1,
				rule = rules[i],
				selector = rules[i][0],
				propStr = '';

			// If the second argument of a rule is an array of arrays, correct our variables.
			if (Object.prototype.toString.call(rule[1][0]) === '[object Array]') {
				rule = rule[1];
				j = 0;
			}

			for (let pl = rule.length; j < pl; j++) {
				var prop = rule[j];
				propStr += prop[0] + ':' + prop[1] + (prop[2] ? ' !important' : '') + ';\n';
			}

			// Insert CSS Rule
			styleSheet.insertRule(selector + '{' + propStr + '}', styleSheet.cssRules.length);
		}
	}

	existSkin() {
		return this.domHelpersSvc.getById('providerTheme') !== null;
	}

	hexToRgb(hex) {
		// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		const fullHex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);

		return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
	}
}

let compMod = angular
	.module('mod.svc.ThemingService', [])
	.service('ColorService', Color)
	.service('ColorSolverService', Solver)
	.service('ThemingService', Theming);
export default compMod = compMod.name;
