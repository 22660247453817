export class Intercom {
	constructor($log, $window, $timeout, $state, AuthService) {
		'ngInject';
		this.log = $log;
		this.window = $window;
		this.timeout = $timeout;
		this.state = $state;
		this.authService = AuthService;
	}

	/**
	 * @memberof IntercomService
	 */
	boot(currentUser) {
		if (currentUser && 'Intercom' in this.window && typeof this.window.Intercom === 'function') {
			try {
				this.window.Intercom('boot', {
					app_id: 'wmg4i4pq',
					email: currentUser.email,
					user_id: currentUser.id,
					name: currentUser.first_name + ' ' + currentUser.last_name,
					last_login: currentUser.last_login,
					from_app: 'App',
					from_screen: this.state.current.name,
					created_at: Date.now(),
					user_role: this.authService.isAdmin(currentUser) ? 'admin' : 'consultant',
					company: {
						id: currentUser.service_provider_id,
						name: currentUser.service_provider_name,
						phone: currentUser.mobile_phone,
					},
					avatar: {
						type: 'avatar',
						image_url: currentUser.logo_url,
					},
				});
				this.log.info('Intercom:boot');
			} catch (e) {
				this.log.warn(e);
			}
		} else {
			this.timeout(() => this.boot(currentUser), 60000);
		}
	}

	/**
	 * Calling the update without arguments will look for new messages that
	 * should be displayed to the current user.
	 *
	 * Calling the update with a object of user details will update those
	 * fields on the current user and looking for new messages for the user.
	 *
	 * @param {Object} user object data
	 * @memberof IntercomService
	 * @Deprecated
	 */
	update(object) {
		if ('Intercom' in this.window && angular.isFunction(this.window.Intercom)) {
			try {
				this.window.Intercom('update', object);
				this.log.info('Intercom:update');
			} catch (e) {
				this.log.warn(e);
			}
		}
	}

	shutdown() {
		if ('Intercom' in this.window && angular.isFunction(this.window.Intercom)) {
			try {
				this.window.Intercom('shutdown');
				this.log.info('Intercom:shutdown');
			} catch (e) {
				this.log.warn(e);
			}
		}
	}

	/**
	 * This will hide the main Messenger panel. It will not hide the Launcher.
	 *
	 * @memberof IntercomService
	 * @Deprecated
	 */
	hide() {
		if ('Intercom' in this.window && angular.isFunction(this.window.Intercom)) {
			try {
				this.window.Intercom('hide');
				this.log.info('Intercom:hide');
			} catch (e) {
				this.log.warn(e);
			}
		}
	}
}
//
let compMod = angular.module('mod.svc.intercomService', []).service('IntercomService', Intercom);
export default compMod = compMod.name;
