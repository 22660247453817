const stateChangeError = $injector => {
	const $rootScope = $injector.get('$rootScope');

	const onStateChangeError = $rootScope.$on('$stateChangeError', (event, toState, toParams, fromState, fromParams) => {
		const $log = $injector.get('$log');
		$log.error(event, toState, toParams, fromState, fromParams);
	});
	$rootScope.$on('$destroy', onStateChangeError);
};
stateChangeError.$inject = ['$injector'];
export default stateChangeError;
