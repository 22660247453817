const onAuthEvents = $injector => {
	const $rootScope = $injector.get('$rootScope');
	const $uibModal = $injector.get('$uibModal');
	const $uibModalStack = $injector.get('$uibModalStack');
	const $state = $injector.get('$state');
	const $log = $injector.get('$log');
	const $q = $injector.get('$q');
	const HttpBuffer = $injector.get('HttpBuffer');
	const AuthSvc = $injector.get('AuthService');
	const ServiceProviderUserService = $injector.get('ServiceProviderUserService');
	const MetricsSvc = $injector.get('MetricsService');
	const AUTH_EVENTS = $injector.get('AUTH_EVENTS');
	const ENV = $injector.get('ENV');
	const VER = $injector.get('VERSION');

	// go to state
	function goToState(stateName) {
		$state.go(stateName, {}, {location: 'replace', reload: true});
	}

	// update http config with user token
	function updateHttpConfig(userToken) {
		return function updateHttp(config) {
			config.headers.Auth = userToken;
			return config;
		};
	}

	// OCA login required with req buffer
	function openOcaModalLogin(eventData) {
		$uibModal
			.open({
				backdrop: 'static',
				keyboard: false,
				component: 'loginForm',
				size: 'xs login',
				resolve: {
					data() {
						return eventData;
					},
				},
			})
			.result.then(closeObj => {
				$rootScope.$broadcast(AUTH_EVENTS.OCA_CONFIRMED, closeObj);
			})
			.catch(angular.noop);
	}

	function getUserFeatures(userId) {
		const _errMsg = {display: 'Error getting user features'};
		const _deferred = $q.defer();
		ServiceProviderUserService.getFeatures({userId: userId})
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) _deferred.resolve(data.payLoad);
				if (data?.error) _deferred.reject(Object.assign(data.error, _errMsg));
			})
			.catch(err => _deferred.reject(Object.assign(err.data.error, _errMsg)));
		return _deferred.promise;
	}

	// #region AUTH
	function onOcaLogin(data) {
		if (ENV !== 'production') $log.info('Oca login required with data: ', data);
		$uibModalStack.dismissAll(); // close modal
		goToState('user.login');
	}
	function onAuth0Login(data) {
		if (VER !== 'production') $log.info('Auth0 login required with data: ', data);
		goToState('user.auth');
	}
	function onOcaLoginWithBuffer(data) {
		if (ENV !== 'production') $log.info('OCA login with buffer required with data: ', data);
		openOcaModalLogin(data.config);
	}
	function onAuth0LoginWithBuffer(data) {
		if (VER !== 'production') $log.info('Auth0 login with buffer required with data: ', data);

		let accessToken;
		const userObj = AuthSvc.currentUser();
		const auth0Client = AuthSvc.getAuth0Client();
		auth0Client
			.getTokenSilently()
			.then(jwt => {
				if (jwt) {
					accessToken = AuthSvc.setAccessToken(jwt);
					MetricsSvc.log('get-token-silently', {ver: VER});
					return getUserFeatures(userObj.id);
				} else {
					return $q.reject({error_description: 'Unable to get accessToken'});
				}
			})
			.then(features => {
				AuthSvc.setUserFeatures(features);
				MetricsSvc.log('get-user-features', {feat: features});
				if (data?.config?.fromState) {
					goToState(data?.config?.fromState);
				} else {
					const _updateHttpConfig = new updateHttpConfig(accessToken);
					HttpBuffer.retryAll(_updateHttpConfig);
				}
			})
			.catch(errObj => {
				// errObj.error 'invalid_grant', 'missing_refresh_token'
				$log.error(`Fail getting token silently: `, errObj.error);
				AuthSvc.removeSession();
				onAuth0Login(data);
			});
	}
	// Event listeners

	// login required
	const onLoginRequired = $rootScope.$on(AUTH_EVENTS.LOGIN, (e, data) => {
		if (VER !== 'production') $log.info(`even ${AUTH_EVENTS.LOGIN} handled with data: `, e, data);

		AuthSvc.isAuth0Flow()
			.then(isAuth0Flow => {
				const eventName = isAuth0Flow ? AUTH_EVENTS.AUTH0_LOGIN : AUTH_EVENTS.OCA_LOGIN;
				MetricsSvc.log(eventName, {ver: VER});

				if (isAuth0Flow) {
					onAuth0Login(data);
				} else {
					onOcaLogin(data);
				}
			})
			.catch(resObj => {
				$log.error(resObj);
			});
	});
	$rootScope.$on('$destroy', onLoginRequired);

	// login with buffer
	const onLoginWithBufferRequired = $rootScope.$on(AUTH_EVENTS.BUFFER_LOGIN, (e, data) => {
		if (VER !== 'production') $log.info(`even ${AUTH_EVENTS.BUFFER_LOGIN} handled with data: `, e, data);
		AuthSvc.isAuth0Flow()
			.then(isAuth0Flow => {
				const eventName = isAuth0Flow ? AUTH_EVENTS.AUTH0_BUFFER_LOGIN : AUTH_EVENTS.OCA_BUFFER_LOGIN;
				MetricsSvc.log(eventName, {ver: VER});

				if (isAuth0Flow) {
					onAuth0LoginWithBuffer(data);
				} else {
					onOcaLoginWithBuffer(data);
				}
			})
			.catch(resObj => {
				$log.error(resObj);
			});
	});
	$rootScope.$on('$destroy', onLoginWithBufferRequired);

	// oca login confirmed
	const onOcaLoginConfirmed = $rootScope.$on(AUTH_EVENTS.OCA_CONFIRMED, (e, data) => {
		if (VER !== 'production') $log.info(`even ${AUTH_EVENTS.OCA_CONFIRMED} handled with data: `, e, data);

		// close modal
		$uibModalStack.dismissAll();

		// TODO: pass data.jwt and set as cookie
		// use AuthSvc setAccessToken method

		// if super redirect
		const isSuper = AuthSvc.isSuper(data.user);
		if (isSuper) {
			HttpBuffer.clearBuffer({data: 'as inpersonator'});
			goToState('user.super');
		} else if ($state.current.name === 'user.login') {
			goToState('navigator');
		} else if (data?.config?.headers['X-State-Name']) {
			HttpBuffer.clearBuffer({data: 'from route state resolver'});
			goToState(data?.config.headers['X-State-Name']);
		} else {
			const _token = AuthSvc.getAccessToken();
			const _updateHttpConfig = new updateHttpConfig(_token);
			HttpBuffer.retryAll(_updateHttpConfig);
		}
	});
	$rootScope.$on('$destroy', onOcaLoginConfirmed);

	// auth0 login confirmed
	const onAuth0LoginConfirmed = $rootScope.$on(AUTH_EVENTS.AUTH0_CONFIRMED, (e, data) => {
		if (VER !== 'production') $log.info(`even ${AUTH_EVENTS.AUTH0_CONFIRMED} handled with data: `, e, data);

		MetricsSvc.log(AUTH_EVENTS.AUTH0_CONFIRMED, {ver: VER});
		AuthSvc.setAccessToken(data.jwt);
		// TODO: initialize Intercom & Googletagmanager

		// if super redirect
		const isSuper = AuthSvc.isSuper(data.user);
		if (isSuper) {
			goToState('user.super');
			return false;
		}

		if ($state.current.name === 'user.auth') {
			goToState('navigator');
		}
	});
	$rootScope.$on('$destroy', onAuth0LoginConfirmed);
	// #endregion
};
onAuthEvents.$inject = ['$injector'];
export default onAuthEvents;
