class Proposal {
	constructor($http, API, PUBLIC_KEY) {
		'ngInject';
		this.API = API;
		this.http = $http;
		this.gateway = '/proposal';
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	/**
	 * @param {Object} params
	 * @param {string} params.hideExcludedSystemTypes
	 * @param {boolean} [doCache=true]
	 * @returns {Promise} Promise object
	 * @memberof Proposal
	 */
	getAllProposalTypes(params, doCache = true) {
		return this.http.get(`${this.API}/v1${this.gateway}/allProposalTypes`, {params: params, catch: doCache});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	saveProposalCheckListAnswers(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/saveProposalChecklistAnswers`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {string} params.templateType
	 * @param {boolean} params.excludeEmptySizes
	 * @param {boolean} params.hideExcludedSystemTypes
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getAllProposalTypesByServiceProviderAndTemplateType(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/allProposalTypesByServiceProviderAndTemplateType`, {params});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getAllProposalTypeOptionsByServiceProviderAndSystemType(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/allProposalTypeOptionsByServiceProviderAndSystemType`, {
			params,
		});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.systemTypeId
	 * @param {string} params.systemTonnage
	 * @param {string} params.furnaceConfiguration
	 * @param {string} params.bundleType
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getAllBundlesForProposal(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/allBundlesForProposal`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {string} params.proposalOptionsForPrice
	 * @param {number} params.systemTypeId
	 * @param {number} params.serviceProviderId
	 * @param {number} params.equipmentPricingProfileId
	 * @param {boolean} params.usingCustomBundle
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getBundlePriceAndAvailabilityForSystemPreferences(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/bundlePriceAndAvailabilityForSystemPreferences`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getPreviewOfEquipmentProposalSelection(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/previewOfTheProposals`, {params});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	addSystemToProposal(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/addSystemToProposal`, params);
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	removeBundleFromProposal(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/removeBundleFromProposal`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.proposalTypeId
	 * @param {string} params.equipmentsJson
	 * @param {string} params.usingCustomBundle
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	replaceEquipmentInBundle(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/replaceEquipmentInBundle`, params);
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	updateProposalConsultant(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/updateProposalConsultant`, params);
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getProposalStateAndStatus(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/proposalStateAndStatus`, {params});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postSaveProposalStateForCustomer(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.API}/v1${this.gateway}/saveProposalStateForCustomer`, params, config);
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	updateProposalStatusToDraft(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/updateProposalStatusToDraft`, params);
	}

	/**
	 * @param {Object} params
	 * @param {string} params.proposalOptionsForPrice
	 * @param {number} params.systemTypeId
	 * @param {number} params.serviceProviderId
	 * @param {number} params.equipmentPricingProfileId
	 * @param {string} params.usingCustomBundle
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getBundlePricingBreakDown(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/bundlePricingBreakdown`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getProposals(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/proposals`, {params});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postCleanPreviousBundlesSelection(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/cleanPreviousBundlesSelection`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.systemTypeId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getBundleSkeletonBySystemType(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/bundleSkeletonBySystemType`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.proposalId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getConsultationHasAccessories(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/consultationHasAccessories`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.eventLogId
	 * @param {number} params.position
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getProposalSnapshotDetailsByEventLogAndPosition(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/proposalSnapshotDetailsByEventLogAndPosition`, {params});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getProviderThemeSettings(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/providerThemeSettings`, {params});
	}

	/**
	 * @Deprecated not in use
	 * @param {Object} params
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postSaveSystemName(params) {
		return this.http.post(`${this.API}/v1${this.gateway}/saveSystemName`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.deposit
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postUpdateDeposit(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/updateDeposit`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.financingId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postSaveFinancingOptionSelected(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/saveFinancingOptionSelected`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.targetTotalInvestment
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getOverridePriceData(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/overriddenPriceData`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {string} params.overriddenPriceData
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postOverrideProposalsPrice(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/overrideProposalsPrice`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postResetOverriddenProposalPrices(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/resetOverriddenProposalPrices`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.systemTypeId
	 * @param {string} params.bundleType
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getAllBundlesForPricingBook(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/allBundlesForPricingBook`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.systemTypeId
	 * @param {number} params.equipmentPricingProfileId
	 * @param {string} params.bundlesInJsonFormat
	 * @param {string} params.bundleType
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getBundlePriceForPricingBook(params) {
		return this.http.get(`${this.API}/v1${this.gateway}/bundlePriceForPricingBook`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {string} params.label
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postUpdateLabel(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/updateLabel`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.taxRate
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postUpdateTaxRate(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/updateTaxRate`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {Array} params.proposals [number]
	 * @param {string} params.notes
	 * @param {Array} params.attachments [{url: string, type: any, title: string}]
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postAddNotes(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/addNotes`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postRemoveNote(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/removeNotes`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getNotesPreview(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/notesPreview`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getNoteDetails(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/noteDetails`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getPublicNotes(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/noteDetails`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {string} params.customerToken
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getNoteDetailsForCustomer(params) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.API}/v2${this.gateway}/noteDetailsForCustomer`, {params, headers});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.eventLogId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	getPublicNotesForSnapshot(params) {
		return this.http.get(`${this.API}/v2${this.gateway}/noteDetailsFromSnapshot`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.userId
	 * @param {number} params.proposalFinancingId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	saveSecondaryFinancingOptionSelected(params) {
		return this.http.post(`${this.API}/v2${this.gateway}/saveSecondaryFinancingOptionSelected`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.customerToken
	 * @param {number} params.proposalFinancingId
	 * @returns {Promise}
	 * @memberof Proposal
	 */
	postSaveSecondaryFinancingOptionSelectedForCustomer(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(
			`${this.API}/v2${this.gateway}/saveSecondaryFinancingOptionSelectedForCustomer`,
			params,
			config
		);
	}
}
//
let compMod = angular.module('mod.svc.proposalService', []).service('ProposalService', Proposal);
export default compMod = compMod.name;
