const stateChangeSuccess = $injector => {
	const NavService = $injector.get('NavService');
	const $rootScope = $injector.get('$rootScope');
	const NotifyingService = $injector.get('NotifyingService');
	const IntercomService = $injector.get('IntercomService');
	const QueuePromisesService = $injector.get('QueuePromisesService');
	const $state = $injector.get('$state');

	const onStateChangeSuccess = $rootScope.$on('$stateChangeSuccess', (event, toState) => {
		// todo: refactor logic to avoid setting parent visibility from route events
		// only used in navigator.child routes
		if (NavService.isParentVisible(toState.name)) {
			NotifyingService.notify('event:parent-Show');
		} else {
			NotifyingService.notify('event:parent-Hide');
		}

		// start/stop Intercom
		if (!NavService.isStatePublic(toState.name)) {
			IntercomService.boot();
		} else {
			IntercomService.shutdown();
		}

		// resume transition
		const $urlRouter = $injector.get('$urlRouter');
		$urlRouter.sync();

		// reset promises queue, todo: refactor to sharing singleton state
		QueuePromisesService.reset();

		// todo: refactor logic to avoid setting parent visibility from route events
		$rootScope.isOffice = $state.includes('office');
	});

	$rootScope.$on('$destroy', onStateChangeSuccess);
};
stateChangeSuccess.$inject = ['$injector'];
export default stateChangeSuccess;
