const configMisc = $injector => {
	const ENV = $injector.get('ENV');

	// $q
	const $qProvider = $injector.get('$qProvider');
	$qProvider.errorOnUnhandledRejections(false);

	// $compile
	if (ENV === 'production') {
		const $compileProvider = $injector.get('$compileProvider');
		$compileProvider.debugInfoEnabled(false);
	}

	// ocLazyLoad
	const $ocLazyLoadProvider = $injector.get('$ocLazyLoadProvider');
	// local, test, development, production
	const lazyConfig = ENV === 'debug' ? {events: true, debug: true} : {events: true};
	$ocLazyLoadProvider.config(lazyConfig);

	// $sceDelegate
	const HOST_URL = $injector.get('HOST_URL');
	const API = $injector.get('API');
	const $sceDelegateProvider = $injector.get('$sceDelegateProvider');
	$sceDelegateProvider.resourceUrlWhitelist([`${HOST_URL}/**`, `${API}/**`]);
};
configMisc.$inject = ['$injector'];
export default configMisc;
