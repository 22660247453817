const onServiceUnavailable = $injector => {
	const $rootScope = $injector.get('$rootScope');
	const $uibModal = $injector.get('$uibModal');

	const onServiceUnavailableEvent = $rootScope.$on('event:service-unavailable', (event, data) => {
		alertServiceUnavailable(data);
	});
	$rootScope.$on('$destroy', onServiceUnavailableEvent);

	function alertServiceUnavailable(eventData) {
		$uibModal
			.open({
				keyboard: false,
				component: 'serviceUnavailable',
				windowClass: 'full',
				size: 'full',
				resolve: {
					data() {
						return eventData;
					},
				},
			})
			.result.then(angular.noop)
			.catch(angular.noop);
	}
};

onServiceUnavailable.$inject = ['$injector'];
export default onServiceUnavailable;
