import './svg-icon.scss';
import SvgIconTpl from './svg-icon.html';
//
const compConfig = {
	template: SvgIconTpl,
	controller: IconController,
	bindings: {
		iconName: '<',
		iconSize: '<?',
	},
};
/* @ngInject */
function IconController($log, $scope) {
	const $ctrl = this;
	$ctrl.$onInit = () => {
		$ctrl.icon = null;
		$ctrl.styles = {
			fontSize: $ctrl.iconSize,
		};
		loadIcon($ctrl.iconName);
	};
	$ctrl.$onChanges = changesObj => {
		if (changesObj?.iconName && changesObj?.iconName?.currentValue && !changesObj?.iconName?.isFirstChange()) {
			loadIcon($ctrl.iconName);
		}
	};

	function loadIcon(iconName) {
		import(/* webpackMode: "eager" */ `../../../assets/icons/${iconName}.svg`)
			.then(_m => {
				$ctrl.icon = _m.default;
				$scope.$digest();
			})
			.catch(_e => $log.error(_e));
	}
}
//
let compMod = angular.module('mod.cmp.svgIcon', []).component('svgIcon', compConfig);
export default compMod = compMod.name;
