import ModalErrorTpl from './modal-error.html';
//
/** @ngInject */
function ErrorModalController($window, $state) {
	const $ctrl = this;

	$ctrl.$onInit = () => {
		$ctrl.close = close;
		$ctrl.back = back;
		$ctrl.cancel = cancel;
		$ctrl.reloadRoute = reloadRoute;
		$ctrl.additionalDetails = null;
	};

	function close() {
		$ctrl.modalInstance.close('close');
	}

	function reloadRoute() {
		$ctrl.cancel();
		$state.go($state.current, {}, {reload: true});
	}

	function back() {
		$ctrl.cancel();
		if ($ctrl.resolve.data.previousPageBackMode === true) {
			$window.history.back();
		}
	}

	function cancel() {
		$ctrl.close();
	}
}

const compConfig = {
	template: ModalErrorTpl,
	controller: ErrorModalController,
	bindings: {
		modalInstance: '<',
		resolve: '<',
	},
};

let compMod = angular.module('mod.cmp.modalError', []).component('modalError', compConfig);
export default compMod = compMod.name;
