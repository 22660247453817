export class FinanceOption {
	constructor($http, API, PUBLIC_KEY) {
		'ngInject';
		this.http = $http;
		this.path = `${API}/v1/financingOptions`;
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {boolean} [cache=false]
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getFinancingOptionsByServiceProvider(params, cache = false) {
		return this.http.get(`${this.path}/financingOptionsByServiceProvider`, {params, cache});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {boolean} params.includeDefault
	 * @param {string} params.search
	 * @param {string} params.limit
	 * @param {string} params.offset
	 * @param {string} params.financeTypesJson
	 * @param {string} params.orderBy
	 * @param {string} params.sortOrder
	 * @param {boolean} [cache=false]
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getFinancingOptionsByServiceProviderForAdmin(params, cache = false) {
		return this.http.get(`${this.path}/financingOptionsByServiceProviderForAdmin`, {params, cache});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {string} params.displayName
	 * @param {string} params.notes
	 * @param {number} params.duration
	 * @param {string} params.rate
	 * @param {string} params.minRate
	 * @param {string} params.financingBuffer
	 * @param {string} params.lookupFinancingTypeId
	 * @param {string} params.disclosureTextShort
	 * @param {string} params.disclosureTextLong
	 * @param {string} params.displayTextValue
	 * @param {string} params.financingPlanId
	 * @param {boolean} params.visible
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	createFinanceOption(params) {
		return this.http.post(`${this.path}/createFinanceOption`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.financeOptionId
	 * @param {string} params.displayName
	 * @param {string} params.notes
	 * @param {number} params.duration
	 * @param {string} params.rate
	 * @param {string} params.minRate
	 * @param {string} params.financingBuffer
	 * @param {string} params.lookupFinancingTypeId
	 * @param {string} params.disclosureTextShort
	 * @param {string} params.disclosureTextLong
	 * @param {string} params.displayTextValue
	 * @param {string} params.financingPlanId
	 * @param {boolean} params.visible
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	updateFinanceOption(params) {
		return this.http.post(`${this.path}/updateFinanceOption`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.financeOptionId
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	deactivateFinanceOption(params) {
		return this.http.post(`${this.path}/deactivateFinanceOption`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {boolean} [cache=false]
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getAllLookupFinancingTypes(params, cache = false) {
		return this.http.get(`${this.path}/allLookupFinancingTypes`, {params, cache});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @param {boolean} [cache=false]
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getLookupFinancingTypeByServiceProvider(params, cache = false) {
		return this.http.get(`${this.path}/lookupFinancingTypeByServiceProvider`, {params, cache});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.financeOptionId
	 * @param {boolean} [cache=false]
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getFinancingOptionByIdAndServiceProviderId(params, cache = false) {
		return this.http.get(`${this.path}/financingOptionByIdAndServiceProviderId`, {params, cache});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.financeOptionId
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	postHideFinanceOption(params) {
		return this.http.post(`${this.path}/hideFinanceOption`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.financeOptionId
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	postShowFinanceOption(params) {
		return this.http.post(`${this.path}/showFinanceOption`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.financeOptionId
	 * @param {number} params.userId
	 * @param {boolean} [cache=false]
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getFinancingPlansByLookupFinancingTypeAndServiceProvider(params, cache = false) {
		return this.http.get(`${this.path}/financingPlansByLookupFinancingTypeAndServiceProvider`, {params, cache});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.primaryFinanceOptionId
	 * @param {number} params.proposalId
	 * @param {number} params.userId
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getSecondaryFinancingOptions(params) {
		return this.http.get(`${this.path}/secondaryFinancingOptions`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.proposalId
	 * @param {number} params.userId
	 * @param {Array} params.secondaryFinancingOptions
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	updateSecondaryFinancingOptions(params) {
		return this.http.post(`${this.path}/updateSecondaryFinancingOptions`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.proposalId
	 * @param {number} params.userId
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getAvailableFinancingOptions(params) {
		return this.http.get(`${this.path}/availableFinancingOptions`, {params});
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.consultationId
	 * @param {number} params.proposalId
	 * @param {number} params.userId
	 * @returns {Promise}
	 * @memberof FinanceOptionService
	 */
	getAvailableFinancingOptionsForCustomer(params) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/availableFinancingOptionsForCustomer`, {params, headers});
	}
}
//
let svcMod = angular.module('mod.svc.FinanceOptionService', []).service('FinanceOptionService', FinanceOption);
export default svcMod = svcMod.name;
