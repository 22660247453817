export class QueuePromisesService {
	constructor() {
		this.queue = [];
	}

	add(name) {
		return this.queue.push(name ? name : true) > 0;
	}

	subs() {
		this.queue.pop();
		return this.queue.length > 0;
	}

	reset() {
		this.queue = [];
		return false;
	}
}

let compMod = angular.module('mod.svc.queuePromisesService', []).service('QueuePromisesService', QueuePromisesService);
export default compMod = compMod.name;
