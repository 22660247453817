export class StorageService {
	constructor(localStorageService) {
		'ngInject';
		this.localStorageService = localStorageService;
	}

	/**
	 * gets get a value from local storage
	 * @param key Key identifying the wanted entry.
	 * @returns value from local storage
	 */
	get(key) {
		return this.localStorageService.get(key);
	}

	/**
	 * adds a value to local storage.
	 * @param key Key identifying the entry to save.
	 * @returns boolean
	 */
	set(key, val) {
		return this.localStorageService.set(key, val);
	}

	/**
	 * Return array of keys for local storage
	 * @returns Return array of keys for local storage
	 */
	keys() {
		return this.localStorageService.keys();
	}

	/**
	 * remove an entry for local storage
	 * @param key Key identifying the entry to delete.
	 * @returns boolean
	 */
	remove(key) {
		return this.localStorageService.remove(key);
	}

	/**
	 * remove all data for this app from local storage.
	 * @returns boolean
	 */
	clearAll() {
		return this.localStorageService.clearAll();
	}
}

let compMod = angular.module('mod.svc.StorageService', []).service('StorageService', StorageService);
export default compMod = compMod.name;
