class InterceptorAuth {
	constructor($injector) {
		this.$injector = $injector;
		this.$rootScope = $injector.get('$rootScope');
		this.$cookies = $injector.get('$cookies');
		this.AUTH_KEYS = $injector.get('AUTH_KEYS');
		this.INIT_FLAGS = $injector.get('INIT_FLAGS');
		this.AUTH_EVENTS = $injector.get('AUTH_EVENTS');
		this.PUBLIC_KEY = $injector.get('PUBLIC_KEY');
		this.API = $injector.get('API');

		this.$q = $injector.get('$q');
		this.bufferLength = 0;
		//
		this.request = this.requestFn.bind(this);
		this.response = this.responseFn.bind(this);
		this.responseError = this.responseErrorFn.bind(this);
	}

	requestFn(config) {
		config.headers = config.headers || {};
		const userToken = this.$cookies.get(this.AUTH_KEYS.tokenKey);

		// do not add Auth when calling googleapis or c4c easy-link
		// const thirdParty = /^https:\/\/maps\.googleapis\.com.*/.test(config.url) || /easy-link/.test(config.url);
		// FIXME: to work with local API should be more specific to desambiguate get svg, img (GET)
		const isOcaApi = config.url.includes(this.API);
		if (!config.headers?.Auth && isOcaApi) config.headers.Auth = userToken;

		if (config.headers?.['Content-Type'] && !config?.data) {
			config.data = '';
		}

		return config;
	}

	responseFn(response) {
		// TODO: if needed, session_token should not be returned, ask API
		const authFlowData = this.$cookies.getObject(this.INIT_FLAGS.AUTH0_FLOW);
		if (!authFlowData.value && response?.data?.session_token) {
			this.$cookies.put(this.AUTH_KEYS.tokenKey, response.data.session_token);
		}
		return response;
	}

	responseErrorFn(rejection) {
		const HttpBuffer = this.$injector.get('HttpBuffer');
		const config = rejection.config || {};
		if (rejection.status == 401 || rejection.status == 403) {
			if (this.AUTH_KEYS.bufferReq) {
				const _dfr = this.$q.defer();
				this.bufferLength = HttpBuffer.append(config, _dfr);
				if (this.bufferLength === 1) this.$rootScope.$broadcast(this.AUTH_EVENTS.BUFFER_LOGIN, rejection);
				return _dfr.promise;
			} else {
				this.$rootScope.$broadcast(this.AUTH_EVENTS.LOGIN, rejection);
			}
		}

		return this.$q.reject(rejection);
	}

	static create($injector) {
		return new InterceptorAuth($injector);
	}
}
//
InterceptorAuth.create.$inject = ['$injector'];
export default InterceptorAuth;
