import './single-row.scss';
import SingleRowTpl from './single-row.html';
//
export const compConfig = {
	template: SingleRowTpl,
	transclude: true,
	controller: SingleRowController,
};
/** @ngInject */
function SingleRowController() {
	const $ctrl = this;
	$ctrl.$onInit = () => {};
}
// single-row
let compMod = angular.module('mod.cmp.singleRow', []).component('singleRow', compConfig);
export default compMod = compMod.name;
