export class DOMHelpers {
	constructor($window, $log) {
		'ngInject';
		this.window = $window;
		this.log = $log;
	}

	isSmallView(size = 640) {
		return this.window.document.querySelector('body').getBoundingClientRect().width < size;
	}

	targetInnerWidth(e) {
		return e?.target?.innerWidth || null;
	}

	scrollY(top = null) {
		if (!top) throw new Error('Scroll Y value is required');
		return this.window.scrollY > top;
	}

	scrollHeight() {
		return this.window.document.querySelector('body').scrollHeight;
	}

	querySelector(selector) {
		return this.window.document.querySelector(selector);
	}

	/**
	 * Wrapper method to interface document.getElementById
	 * @param {string} id
	 * @returns Object - https://developer.mozilla.org/en-US/docs/Web/API/Document/getElementById
	 */
	getById(id) {
		return this.window.document.getElementById(id);
	}

	/**
	 * Wrapper method to interface document.getElementsByTagName
	 * @param {string} tagName
	 * @returns Object - https://developer.mozilla.org/en-US/docs/Web/API/Document/getElementsByTagName
	 */
	getByTag(tagName) {
		return this.window.document.getElementsByTagName(tagName);
	}

	querySelectorAll(selector) {
		return this.window.document.querySelectorAll(selector);
	}

	createElement(elm) {
		return this.window.document.createElement(elm);
	}

	insertScriptHead(src, elmId) {
		return new Promise((resolve, reject) => {
			const scriptElm = this.createElement('script');
			scriptElm.type = 'text/javascript';
			scriptElm.async = true;
			scriptElm.id = elmId;
			scriptElm.src = src;
			this.window.document?.head.appendChild(scriptElm);
			scriptElm.onload = () => resolve();
			scriptElm.onerror = message => reject(message);
		});
	}

	appendChild(targetElm, newElm) {
		const target = this.getByTag(targetElm)[0];
		return target.appendChild(newElm);
	}

	insertBefore(targetElm, newElm) {
		const target = this.getByTag(targetElm)[0];
		target.parentNode.insertBefore(newElm, target);
	}

	removeChild(elm) {
		return this.window.document.body.removeChild(elm);
	}

	observe(elements, options) {
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add('reveal-y-in');
					observer.unobserve(entry.target);
				}
			});
		}, options);

		elements.forEach(element => {
			observer.observe(element);
		});
	}

	viewportHeight() {
		return this.window.innerHeight;
	}
}
//
let svcMod = angular.module('mod.svc.DOMHelpersSvc', []).service('DOMHelpersSvc', DOMHelpers);
export default svcMod = svcMod.name;
