import {Auth0Client} from '@auth0/auth0-spa-js';
//
export class Auth {
	#SCOPE = 'openid profile email offline_access';

	constructor(
		$log,
		$cookies,
		$q,
		ENV,
		API,
		ROLE_ALIASES,
		AUTH_KEYS,
		INIT_FLAGS,
		AUTH0_DOMAIN,
		AUTH0_CLIENT_ID,
		HOST_URL,
		LD_CLIENT_ID,
		LDClient,
		TextUtilsService
	) {
		'ngInject';
		this.$cookies = $cookies;
		this.$log = $log;
		this.$q = $q;
		this.TextUtilsService = TextUtilsService;
		this.AUTH_KEYS = AUTH_KEYS;
		this.INIT_FLAGS = INIT_FLAGS;
		this.SUPER_ALIAS = ROLE_ALIASES.super;
		this.ADMIN_ALIAS = ROLE_ALIASES.admin;
		this.CONSULTANT_ALIAS = ROLE_ALIASES.consultant;
		this.user = this.getUser();
		this.auth0Config = {
			domain: AUTH0_DOMAIN,
			clientId: AUTH0_CLIENT_ID,
			scope: this.#SCOPE,
			name: 'OCA Client',
			redirectUri: `${HOST_URL}/user/auth`,
			useRefreshTokens: true,
		};
		this.authClient = new Auth0Client({
			...this.auth0Config,
			authorizationParams: {
				// 'docker' consuming API from docker localhort
				audience: ENV === 'docker' ? 'https://api-dev.oncallair.com' : API,
				redirect_uri: `${HOST_URL}/user/auth`,
			},
		});
		this.LD_CLIENT_ID = LD_CLIENT_ID;
		this.LDClient = LDClient;
	}

	/**
	 * @returns Boolean
	 */
	isLoggedIn() {
		return this.getUser() ? Object.prototype.hasOwnProperty.call(this.user, 'id') : false;
	}

	currentUser() {
		return this.getUser();
	}

	/**
	 * @param {Object} userObj
	 * @returns Boolean
	 */
	isSuper(userObj) {
		return this.hasFeatureAlias(userObj, this.SUPER_ALIAS);
	}

	/**
	 * @param {Object} userObj
	 * @returns Boolean
	 */
	isAdmin(userObj) {
		return this.hasFeatureAlias(userObj, this.ADMIN_ALIAS);
	}

	/**
	 * @param {Object} userObj
	 * @returns Boolean
	 */
	isConsultant(userObj) {
		return this.hasFeatureAlias(userObj, this.CONSULTANT_ALIAS);
	}

	updateUser(userObj) {
		if (!userObj) this.$log.error('User object param is required');
		const decodedProviderName = this.TextUtilsService.decodeHtmlEntities(userObj.service_provider_name);
		const userData = Object.assign(this.user, userObj, {service_provider_name: decodedProviderName});
		this.$cookies.putObject(this.AUTH_KEYS.userKey, userData);
		return this.currentUser();
	}

	/**
	 * @param {Object} user
	 * @param {string} role name
	 * @returns Boolean
	 */
	hasFeatureAlias(user, feature) {
		if (!user || !user.features || !feature) {
			this.$log.error('Invalid params: user, feature');
			return false;
		}
		return user.features?.includes(feature);
	}

	setUser(userObj) {
		if (!userObj) this.$log.error('User object param is required');

		let userData;
		if (userObj.hasOwnProperty(this.AUTH_KEYS.nameSpace)) {
			const {
				direct_pricing_mode,
				first_name,
				last_name,
				logo_url,
				mobile_phone,
				oca_user_id,
				order_url,
				roles,
				service_provider_id,
				service_provider_name,
				theme,
				using_custom_bundle,
				using_multi_system,
			} = userObj[this.AUTH_KEYS.nameSpace];
			userData = {
				id: oca_user_id,
				email: userObj.email,
				first_name,
				last_name,
				logo_url,
				mobile_phone,
				oca_user_id,
				order_url,
				service_provider_id,
				service_provider_name: this.TextUtilsService.decodeHtmlEntities(service_provider_name),
				theme,
				using_custom_bundle,
				using_multi_system,
				direct_pricing_mode,
				capabilities: [],
				features: roles,
			};
		} else {
			userObj.service_provider_name = this.encodeObjValues(userObj.service_provider_name);
			userData = Object.assign({capabilities: []}, userObj);
		}

		this.$cookies.putObject(this.AUTH_KEYS.userKey, userData);
		return this.currentUser();
	}

	getUser() {
		this.user = this.$cookies.getObject(this.AUTH_KEYS.userKey);
		return this.user;
	}

	getUserCapabilities() {
		this.user = this.getUser();
		return this.user.capabilities;
	}

	setUserCapabilities(capabilities) {
		this.user = this.getUser();
		this.user.capabilities = capabilities;
		this.updateUser(this.user);
		return this.user;
	}

	/**
	 * @param {string[]} features - user's roles
	 * @returns {Object} user - current user
	 */
	setUserFeatures(features) {
		this.user = this.getUser();
		if (!this.user) {
			this.$log.error('current user must exist as a cookie');
			return false;
		}
		this.user.features = features;
		this.updateUser(this.user);
		return this.user;
	}

	/**
	 * @param {string} role
	 * @returns {string}
	 */
	getAlias(role) {
		let alias;
		switch (role) {
			case 'admin':
				alias = this.ADMIN_ALIAS;
				break;
			case 'consultant':
				alias = this.CONSULTANT_ALIAS;
				break;
			default:
				this.$log.error('No user feature match');
				break;
		}
		return alias;
	}

	/**
	 * Removes user, token and flags cookies
	 * @returns void
	 */
	removeSession() {
		this.$cookies.remove(this.AUTH_KEYS.userKey);
		this.$cookies.remove(this.AUTH_KEYS.tokenKey);
		this.$cookies.remove(this.INIT_FLAGS.AUTH0_FLOW);
		this.$cookies.remove(this.INIT_FLAGS.ALERT_TIME_NOT_SYNC);
	}

	/**
	 * @returns Promise {Boolean}
	 */
	isAuth0Flow() {
		const authFlowData = this.$cookies.getObject(this.INIT_FLAGS.AUTH0_FLOW);
		const currentTimestamp = new Date().getTime();
		const debounceMilliseconds = 5000;
		if (authFlowData && authFlowData?.iat && currentTimestamp - authFlowData.iat < debounceMilliseconds) {
			return this.$q.when(authFlowData.value);
		}

		const _dfr = this.$q.defer();
		const ctx = {kind: 'user', anonymous: true};
		const options = {diagnosticOptOut: false, sendEvents: false, bootstrap: 'sessionStorage'};
		const client = this.LDClient.initialize(this.LD_CLIENT_ID, ctx, options);
		client
			.waitForInitialization()
			.then(() => {
				const auth0FlowValue = client.variation(this.INIT_FLAGS.AUTH0_FLOW, false);
				this.$cookies.putObject(this.INIT_FLAGS.AUTH0_FLOW, {value: auth0FlowValue, iat: new Date().getTime()});
				_dfr.resolve(auth0FlowValue);
			})
			.catch(errObj => _dfr.reject(errObj));
		return _dfr.promise;
	}

	/**
	 * @returns {Auth0Client}
	 */
	getAuth0Client() {
		return this.authClient;
	}

	/**
	 * @param {string} token
	 * @returns boolean
	 */
	setAccessToken(token) {
		if (typeof token !== 'string') {
			this.$log.error('Token must be a string');
			return false;
		}

		this.$cookies.put(this.AUTH_KEYS.tokenKey, token);
	}

	/**
	 * @returns string
	 */
	getAccessToken() {
		return this.$cookies.get(this.AUTH_KEYS.tokenKey);
	}
}
//
let compMod = angular.module('mod.svc.AuthService', []).service('AuthService', Auth);
export default compMod = compMod.name;
