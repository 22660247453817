import {addWeeks} from 'date-fns';

const configCookiesProvider = $injector => {
	const UtilityService = $injector.get('UtilityServiceProvider').$get();
	const $cookiesProvider = $injector.get('$cookiesProvider');
	const today = new Date();

	$cookiesProvider.defaults.domain = UtilityService.getRootDomain();
	$cookiesProvider.defaults.expires = addWeeks(today, 1);
	$cookiesProvider.defaults.SameSite = 'Lax';
	$cookiesProvider.defaults.secure = true;
	$cookiesProvider.defaults.path = '/';
};

configCookiesProvider.$inject = ['$injector'];
export default configCookiesProvider;
