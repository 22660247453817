/* eslint-disable angular/window-service */
/* eslint-disable angular/interval-service */
/* eslint-disable angular/document-service */
/* eslint-disable angular/timeout-service */
/* eslint-disable angular/log */
// #region dependencies
import angular from 'angular';
// modules
import AngularAnimateMod from 'angular-animate';
import AngularCookiesMod from 'angular-cookies';
import AngularMessagesMod from 'angular-messages';
import AngularSanitizeMod from 'angular-sanitize';
import AngularLocalStorageMod from 'angular-local-storage';
import HeMod from 'he';
import {permission, uiPermission} from 'angular-permission';
import OcLazyLoadMod from 'oclazyload';
import * as LaunchDarklyMod from 'launchdarkly-js-client-sdk';
import {lDConstants} from './app/constants.js';
import IndexConstantsMod, {INIT_FLAGS} from './app/index.constants.js';
import AuthConstantsMod from '@Constants/auth.constants.js';
import IndexConstantsEnvMod from './app/index.environment.constants.js';
// filters
import FormatPhoneFltMod from '@Filters/formatPhone.filter.js';
// controllers
import MainCtrMod from './app/controllers/main.controller.js';
// components
import AppShellCmpMod from '@Components/app-shell/app-shell.component.js';
import LoginFormCmpMod from '@Components/login-form/login-form.component.js';
import ServiceUnavailableCmpMod from '@Components/service-unavailable/service-unavailable.component.js';
import RenderAlertCompMod from '@Components/render-alert/render-alert.component.js';
import SvgIconCmpMod from '@Components/svg-icon/svg-icon.component.js';
import RenderPreCmpMod from '@Components/render-pre/RenderPre.js';
import SingleRowCmpMod from '@Components/single-row/single-row.component.js';
// services && factories
import QueuePromisesSvcMod from '@Services/QueuePromises.svc.js';
import AuthSvcMod from '@Services/Auth.svc.js';
import StorageSvcMod from '@Services/Storage.svc.js';
import ProviderSettingsSvcMod from '@Services/ProviderSettings.svc.js';
import TextUtilsSvcMod from '@Services/TextUtils.svc.js';
import InterceptorAuth from '@Services/InterceptorAuth.js';
import InterceptorStatusCodes from '@Services/InterceptorStatusCode.js';
import HttpBuffer from '@Services/HttpBuffer.js';
import ModalHelperSvcMod from '@Services/ModalHelper.svc.js';
import IntercomSvcMod from '@Services/Intercom.svc.js';
import MetricsSvcMod from '@Services/Metrics.svc.js';
import UtilitySvcMod from '@Services/Utility.svc.js';
import NotifySubscribeSvcMod from '@Services/NotifySubscribe.svc.js';
import ServiceProviderUserSvcMod from '@Services/ServiceProviderUser.svc.js';
import RoutesResolveSvcMod from '@Services/RoutesResolve.svc.js';
import ConsultationSvcMod from '@Services/Consultation.svc.js';
import CustomerScvMod from '@Services/Customer.svc.js';
import NavSvcMod from '@Services/Nav.svc.js';
import ServiceProviderSvcMod from '@Services/ServiceProvider.svc.js';
import ThemingSvcMod from '@Services/ColorTheming.svc.js';
import DOMHelpersSvcMod from '@Services/DOMHelpers.svc.js';
import FeatureFlagsSvcMod from '@Services/FeatureFlags.svc.js';
import StateSettingsSvcMod from '@Services/StateSettings.svc.js';
import DiscountSvcMod from '@Services/DiscountService.js';
import FinanceSvcMod from '@Services/FinanceOptions.svc.js';
import ProposalSvcMod from '@Services/Proposal.svc.js';
// import dependencies
import CollapseMod from 'angular-ui-bootstrap/src/collapse/index.js';
import ModalMod from 'angular-ui-bootstrap/src/modal/index-nocss.js';
import {
	format,
	parseISO,
	formatISO,
	toDate,
	addYears,
	addDays,
	subDays,
	subMonths,
	subYears,
	startOfYear,
	compareAsc,
	differenceInMinutes,
	differenceInHours,
	getUnixTime,
	fromUnixTime,
	isBefore,
} from 'date-fns';
import textAngular from 'textangular';
import AngularUiRouter from '@uirouter/angularjs';
import * as OfflinePluginRuntime from '@lcdp/offline-plugin/runtime.js';
// config
import routesConfig from './routes.js';
import configHttpInterceptors from '@Common/configHttpInterceptor.js';
import configLocalStorage from '@Common/configLocalStorageProvider.js';
import configCookiesProvider from '@Common/configCookiesProvider.js';
import configCompileProvider from '@Common/configCompileProvider.js';
import configLogProvider from '@Common/configLogProvider.js';
import configMisc from '@Common/configMisc.js';
import configTextAngularConfig from '@Common/configTextAngularOptions.js';
// run
import runPermissionsDefinitions from '@Common/runPermissionsDefinitions.js';
import onLoginRequiredEvent from '@Common/runOnAuthEvents.js';
import httpDefaults from '@Common/runHttpDefaults.js';
import stateChangeStart from '@Common/runOnStateChangeStart.js';
import stateChangeSuccess from '@Common/runOnStateChangeSuccess.js';
import stateChangeError from '@Common/runOnStateChangeError.js';
import onServiceUnavailable from '@Common/runOnEventServiceUnavailable.js';
import onUnhandledRejection from '@Common/runOnUnhandledRejection.js';
// directives
import NgVisibleDrtMod from '@Directives/ng-visible.directive.js';
// components
import SplashLoader from '@Components/splash-loader/splash-loader.component.js';
import ModalAlertCmpMod from '@Components/modal-alert/modal-alert.component.js';
import ModalErrorCmpMod from '@Components/modal-error/modal-error.component.js';
// styles
import './index.scss';
// #endregion
let appMod = angular
	.module('ocaApp', [
		// third party
		AngularUiRouter,
		AngularAnimateMod,
		AngularCookiesMod,
		AngularSanitizeMod,
		AngularMessagesMod,
		AngularLocalStorageMod,
		permission,
		uiPermission,
		OcLazyLoadMod,
		textAngular,
		ModalMod,
		CollapseMod,
		// svc
		FeatureFlagsSvcMod,
		AuthSvcMod,
		DOMHelpersSvcMod,
		ServiceProviderUserSvcMod,
		ProviderSettingsSvcMod,
		UtilitySvcMod,
		TextUtilsSvcMod,
		ConsultationSvcMod,
		StorageSvcMod,
		CustomerScvMod,
		NavSvcMod,
		NotifySubscribeSvcMod,
		ServiceProviderSvcMod,
		ThemingSvcMod,
		QueuePromisesSvcMod,
		ModalHelperSvcMod,
		MetricsSvcMod,
		IntercomSvcMod,
		RoutesResolveSvcMod,
		IndexConstantsMod,
		IndexConstantsEnvMod,
		AuthConstantsMod,
		FinanceSvcMod,
		ProposalSvcMod,
		DiscountSvcMod,
		StateSettingsSvcMod,
		// filters
		FormatPhoneFltMod,
		// ctrl
		MainCtrMod,
		// cmp
		RenderAlertCompMod,
		SvgIconCmpMod,
		SingleRowCmpMod,
		RenderPreCmpMod,
		AppShellCmpMod,
		LoginFormCmpMod,
		ServiceUnavailableCmpMod,
		ModalAlertCmpMod,
		ModalErrorCmpMod,
		// drt
		NgVisibleDrtMod,
	])
	// config
	.config(routesConfig)
	.config(configHttpInterceptors)
	.config(configLocalStorage)
	.config(configCookiesProvider)
	.config(configCompileProvider)
	.config(configLogProvider)
	.config(configMisc)
	.config(configTextAngularConfig)
	// run
	.run(runPermissionsDefinitions)
	.run(onLoginRequiredEvent)
	.run(onServiceUnavailable)
	.run(httpDefaults)
	.run(stateChangeStart)
	.run(stateChangeSuccess)
	.run(stateChangeError)
	.run(onUnhandledRejection)
	.component('splashLoader', SplashLoader)
	// factories
	.factory('InterceptorAuth', InterceptorAuth.create)
	.factory('InterceptorStatusCodes', InterceptorStatusCodes.create)
	// services
	.service('HttpBuffer', HttpBuffer)
	// constants
	.constant('LDClient', LaunchDarklyMod)
	.constant('LD_CLIENT_ID', lDConstants.LD_CLIENT_ID)
	.constant('dateFns', {
		format,
		formatISO,
		toDate,
		addYears,
		addDays,
		parseISO,
		subDays,
		subMonths,
		subYears,
		startOfYear,
		compareAsc,
		differenceInMinutes,
		differenceInHours,
		getUnixTime,
		fromUnixTime,
		isBefore,
	})
	.constant('He', HeMod);
// bootstrap manually with strict DI
function bootstrapApplication() {
	// bootstrap the application
	OfflinePluginRuntime.install({
		// Update started in background
		onInstalled: obj => {
			console.info(`[SW]:onInstalled: `, obj.source);
			console.info(`[SW]:onInstalled: ${window.location.pathname}`);
		},
		onUpdating: obj => {
			console.info(`[SW]:onUpdating: `, obj.source);
			console.info(`[SW]:onUpdating: ${window.location.pathname}`);
		},
		// Update is ready to be applied
		onUpdateReady: obj => {
			console.info(`[SW]:onUpdateReady: `, obj.source);
			console.info(`[SW]:onUpdateReady: ${window.location.pathname}`);
			OfflinePluginRuntime.applyUpdate();
		},
		// Update was applied
		onUpdated: obj => {
			console.info(`[SW]:onUpdated: : `, obj.source);
			console.info(`[SW]:onUpdated: ${window.location.pathname}`);
			// window.location.reload();
		},
		// Update failed
		onUpdateFailed: obj => {
			console.info(`[SW]:onUpdateFailed: `, obj.source);
			console.info(`[SW]:onUpdateFailed: ${window.location.pathname}`);
		},
	});

	const appContainer = document.getElementById('ocaApp');
	const app = angular.bootstrap(appContainer, ['ocaApp']);
	// enable strict dependency injection
	app.annotate = angular.injector(['ng']).annotate;

	// define the event handler
	const $rootScope = app.get('$rootScope');
	$rootScope.$on('appBootstrapped', () => {
		const splashLogoElm = document.getElementById('splashLogo');
		if (splashLogoElm) splashLogoElm.classList.add('fade');

		setTimeout(() => {
			const loadingSpinnerElm = document.getElementById('loadingSpinner');
			if (loadingSpinnerElm) loadingSpinnerElm.style.display = 'none';
		}, 2000);
	});

	// Emit the event after defining the handler
	$rootScope.$emit('appBootstrapped');
}

function storeAsCookie(key, value) {
	const data = JSON.stringify({value: value, iat: new Date().getTime()});
	document.cookie = `${key}=${encodeURIComponent(data)}; SameSite=Lax; Secure; path=/`;
}

angular.element(document).ready(() => {
	const loadingSpinnerElm = document.getElementById('loadingSpinner');
	if (loadingSpinnerElm) loadingSpinnerElm.style.display = 'block';

	if (!bootstrapApplication.hasRun) {
		const ctx = {kind: 'user', anonymous: true};
		const options = {diagnosticOptOut: false, sendEvents: false, bootstrap: 'sessionStorage'};
		const client = LaunchDarklyMod.initialize(lDConstants.LD_CLIENT_ID, ctx, options);

		client
			.waitForInitialization()
			.then(() => {
				[INIT_FLAGS.ALERT_TIME_NOT_SYNC, INIT_FLAGS.AUTH0_FLOW].forEach(flag => {
					const value = client.variation(flag, false);
					storeAsCookie(flag, value);
				});

				bootstrapApplication();
				bootstrapApplication.hasRun = true;
			})
			.catch(errObj => console.error(errObj));
	}
});

export default appMod = appMod.name;
