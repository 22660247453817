// #region dependencies
import './login-form.scss';
import LoginFormTpl from './login-form.html';
import AppLogoCmpMod from '@Components/app-logo/app-logo.component.js';
// #endregion
const compConfig = {
	template: LoginFormTpl,
	controller: LoginFormController,
	bindings: {
		modalInstance: '<',
		resolve: '<',
	},
};
/** @ngInject */
function LoginFormController(
	$rootScope,
	$log,
	$q,
	$window,
	ServiceProviderUserService,
	StorageService,
	$state,
	AuthService,
	IntercomService,
	UtilityService,
	QueuePromisesService,
	REGEX,
	AUTH_EVENTS,
) {
	const $ctrl = this;
	$ctrl.$onInit = () => {
		// bound vars
		// fixme: refactor to alert component
		$ctrl.showMsg = false;
		$ctrl.message = null;
		$ctrl.credentials = {};
		$ctrl.running = false;
		$ctrl.emailPattern = REGEX.email;
		const viewInfoUrl = $state.href('user.info', {topic: 'cookies'});
		$ctrl.cookiesMsg = `Your browser has cookies disabled. Make sure your cookies are enabled. Follow <a href="${viewInfoUrl}" role="button">instructions</a> to resolve issue.`;
		$ctrl.showPassword = false;
		$ctrl.user = {email: '', password: ''};

		// bound functions
		$ctrl.login = login;
		$ctrl.toggleShowPass = toggleShowPass;
		$ctrl.forgotPassword = forgotPassword;
		$ctrl.onChangeCredentials = onChangeCredentials;

		// initialization
		IntercomService.shutdown();
		getLastEmail();
		checkCookies();
		// focus email field
		$window.document.getElementById('email').focus();
		//
		init();
	};

	function init() {
		AuthService.isAuth0Flow()
			.then(isAuth0Flow => {
				if (isAuth0Flow) $state.go('user.auth');
			})
			.catch(handleRejection);
	}

	function checkCookies() {
		$ctrl.cookiesDisabled = !UtilityService.areCookiesEnabled();
	}

	function getLastEmail() {
		$ctrl.user.email = StorageService.get('last_login_email') || null;
	}

	function onChangeCredentials() {
		$ctrl.showMsg = false;
	}

	// updater function to update buffered requests
	function doLogin(params) {
		const _errMsg = {display: 'Error login user'};
		const _deferred = $q.defer();
		ServiceProviderUserService.postLogin(params)
			.then(resObj => {
				const {data} = resObj;
				if (data?.success) _deferred.resolve(data.payLoad, data.session_token);
				if (data?.error) _deferred.reject(Object.assign(data.error, _errMsg));
			})
			.catch(err => _deferred.reject(Object.assign(err.data.error, _errMsg)));
		return _deferred.promise;
	}

	function login() {
		if ($ctrl.loginForm.$invalid) return false;

		$ctrl.running = QueuePromisesService.add();

		doLogin({
			email: $ctrl.user.email,
			password: $ctrl.user.password,
		})
			.then(handle_doLogin)
			.catch(handleRejection)
			.finally(() => {
				$ctrl.running = QueuePromisesService.subs();
			});
	}

	function handle_doLogin(res) {
		// store last email
		StorageService.set('last_login_email', res.email);

		// store user
		AuthService.setUser(res);

		// boot intercom
		IntercomService.boot();

		// if modal, close with data or emit logged in event
		if ($ctrl?.resolve?.data) {
			$ctrl.modalInstance.close({user: res, config: $ctrl.resolve.data});
		} else {
			$rootScope.$broadcast(AUTH_EVENTS.OCA_CONFIRMED, {user: AuthService.currentUser(), config: null});
		}
	}

	function handleRejection(response) {
		switch (response.code) {
			case 1272:
				$ctrl.showMsg = true;
				$ctrl.message = 'Service Provider account deactivated. Contact us';
				break;
			case 1252:
				$ctrl.showMsg = true;
				$ctrl.message = 'Wrong password/email entered.';
				break;
			case 1253:
				$ctrl.showMsg = true;
				$ctrl.message = 'You have entered invalid login credentials.';
				break;
			case 1254:
				$ctrl.showMsg = true;
				$ctrl.message = 'Your account is inactive, please contact your provider.';
				break;
			default:
				$ctrl.showMsg = false;
				break;
		}
		$log.error(response);
	}

	function toggleShowPass() {
		$ctrl.showPassword = !$ctrl.showPassword;
	}

	function forgotPassword() {
		$state.go('user.forgotPass');
	}
}
// login-form
let compMod = angular.module('mod.cmp.LoginForm', [AppLogoCmpMod]).component('loginForm', compConfig);
export default compMod = compMod.name;
