export const RenderPre = {
	template: '<pre id="renderPre">{{$ctrl.itemData|json}}</pre>',
	controller: RenderPreController,
	bindings: {
		itemData: '<',
		logData: '<?',
	},
};
class RenderPreController {
	$onInit() {
		if (this.logData) {
			console.log('renderPre > onInit > ', this.itemData); // eslint-disable-line
		}
	}
	$onChanges(changesObj) {
		if (changesObj.itemData.currentValue && this.logData) {
			console.log('renderPre > onChanges > ', changesObj.itemData); // eslint-disable-line
		}
	}
}
//
let compMod = angular.module('mod.cmp.renderPre', []).component('renderPre', RenderPre);
export default compMod = compMod.name;
