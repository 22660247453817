/** @ngInject */
function ngVisible() {
	return {
		restrict: 'A',
		multiElement: true,
		link: (scope, element, attr) => {
			scope.$watch(attr.ngVisible, visible => {
				element[visible ? 'removeClass' : 'addClass']('invisible');
			});
		},
	};
}
//
let compMod = angular.module('mod.drt.ngVisible', []).directive('ngVisible', ngVisible);
export default compMod = compMod.name;
