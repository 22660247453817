import {datadogRum} from '@datadog/browser-rum';
//
export class MetricsService {
	constructor($log, ENV, AuthService, VERSION, $window, DATA_DOG_CONFIG, G_TAG_ID) {
		'ngInject';
		this.env = ENV;
		this.$log = $log;
		this.authService = AuthService;
		this.datadogRum = datadogRum;
		this.APP_VERSION = VERSION.replace(/version_/i, '').replace(/_/g, '.');
		this.window = $window;
		this.guess = {id: null, name: 'Guess', email: null};
		this.datadogConfig = DATA_DOG_CONFIG;
		this.GT_ID = G_TAG_ID;
		// eslint-disable-next-line angular/window-service
		this.dataLayer = window.dataLayer || [];
		// eslint-disable-next-line angular/window-service
		window.dataLayer = window.dataLayer || [];
		$window.addEventListener('message', e => {
			if (e.origin === 'https://www.googletagmanager.com') {
				this.setDataLayer(e.data.dataLayer);
			}
		});
	}

	/**
	 * @memberof MetricsService
	 */
	init() {
		this.$log.info(`MetricsService started for ${this.env}`);
		// RUM
		this.datadogRum.init({
			beforeSend: event => {
				if (event.type === 'resource') return false;
				event.view.url = this.window.location.href;
			},
			applicationId: this.datadogConfig[this.env]?.applicationId,
			clientToken: this.datadogConfig[this.env]?.clientToken,
			useCrossSiteSessionCookie: true,
			site: 'datadoghq.com',
			service: this.datadogConfig[this.env]?.service,
			env: this.env,
			version: this.APP_VERSION, // Specify a version number to identify the deployed version
			sampleRate: 100,
			trackInteractions: true,
			defaultPrivacyLevel: 'allow',
			// enableExperimentalFeatures: ['feature_flags'],
		});

		this.setUser();

		this.gtag('config', this.GT_ID);
	}

	/**
	 * @param {string} name
	 * @param {Object} dataObj
	 * @memberof MetricsService
	 */
	log(name, dataObj) {
		this.datadogRum.addAction(name, dataObj);
		this.gtag('event', name, dataObj);
	}

	/**
	 * @param {Object} user
	 * @memberof MetricsService
	 */
	setUser(user) {
		let _currentUser;
		if (user) _currentUser = user;
		if (!user) _currentUser = this.getCurrentUser();
		this.datadogRum.setUser({
			id: _currentUser.id,
			name: `${_currentUser.first_name} ${_currentUser.last_name}`,
			email: _currentUser.email,
		});
		this.gtag('config', this.GT_ID, {user_id: _currentUser.id});
	}

	/**
	 * @memberof MetricsService
	 */
	resetUser() {
		this.setUser(this.guess);
	}

	gtag() {
		// eslint-disable-next-line angular/window-service
		if (window && window?.dataLayer) {
			// eslint-disable-next-line no-undef
			dataLayer.push(arguments);
		} else {
			this.$log('dataLayer nor available', arguments);
		}
	}

	getCurrentUser() {
		const _currentUser = this.authService.currentUser();
		if (!_currentUser) return this.guess;
		return _currentUser;
	}

	setDataLayer(newDataLayer) {
		this.dataLayer = newDataLayer;
	}
}
//
let compMod = angular.module('mod.svc.metricsService', []).service('MetricsService', MetricsService);
export default compMod = compMod.name;
