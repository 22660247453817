export class Consultation {
	constructor($http, API, PUBLIC_KEY) {
		'ngInject';
		this.http = $http;
		this.path = `${API}/v1/consultation`;
		this.PUBLIC_KEY = PUBLIC_KEY;
	}

	getCustomersAndItsConsultations(params, cache = false) {
		return this.http.get(`${this.path}/customersAndItsConsultations`, {params, cache});
	}

	getEventLogsByConsultationId(params, cache = false) {
		return this.http.get(`${this.path}/eventLogsByConsultationId`, {params, cache});
	}

	postCreateConsultation(params) {
		return this.http.post(`${this.path}/createConsultation`, params);
	}

	postUpdateConsultationName(params) {
		return this.http.post(`${this.path}/updateConsultationName`, params);
	}

	postSaveConsultationState(params) {
		return this.http.post(`${this.path}/saveConsultationState`, params);
	}

	postSaveConsultationStateForCustomer(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/saveConsultationStateForCustomer`, params, config);
	}

	postUpdateAssignedConsultant(params) {
		return this.http.post(`${this.path}/updateAssignedConsultant`, params);
	}

	getSurveyQuestions(params, cache = false) {
		return this.http.get(`${this.path}/surveyQuestions`, {params, cache});
	}

	postSaveConsultationCheckListAnswers(params) {
		return this.http.post(`${this.path}/saveConsultationChecklistAnswers`, params);
	}

	getNewSystemConfiguration(params, cache = false) {
		return this.http.get(`${this.path}/newSystemConfiguration`, {params, cache});
	}

	postValidateConsultationStateAndStatus(params) {
		return this.http.post(`${this.path}/validateConsultationStateAndStatus`, params);
	}

	getConsultationStateAndStatusForCustomer(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/consultationStateAndStatusForCustomer`, {params, cache, headers});
	}

	postSaveNewSystemConfiguration(params) {
		return this.http.post(`${this.path}/saveNewSystemConfiguration`, params);
	}

	postAddSystem(params) {
		return this.http.post(`${this.path}/addSystem`, params);
	}

	postRemoveSystem(params) {
		return this.http.post(`${this.path}/removeSystem`, params);
	}

	postCleanDataAssociatedToTheConsultation(params) {
		return this.http.post(`${this.path}/cleanDataAssociatedToTheConsultation`, params);
	}

	postAddAccessoryPlaceholder(params) {
		return this.http.post(`${this.path}/addAccessoryPlaceholder`, params);
	}

	getAllAccessoryPlaceholders(params, cache = false) {
		return this.http.get(`${this.path}/allAccessoryPlaceholders`, {params, cache});
	}

	postUpdateEquipmentPricingProfileOnConsultation(params) {
		return this.http.post(`${this.path}/updateEquipmentPricingProfileOnConsultation`, params);
	}

	postRemoveAccessoryPlaceholder(params) {
		return this.http.post(`${this.path}/removeAccessoryPlaceholder`, params);
	}

	postUpdateSystemsPriceByEquipmentPricingProfile(params) {
		return this.http.post(`${this.path}/updateSystemsPriceByEquipmentPricingProfile`, params);
	}

	postUpdateSystemName(params) {
		return this.http.post(`${this.path}/updateSystemName`, params);
	}

	getConsultationHasAccessories(params, cache = false) {
		return this.http.get(`${this.path}/consultationHasAccessories`, {params, cache});
	}

	postConvertUnmatchedFinanceOptionsToDefault(params) {
		return this.http.post(`${this.path}/convertUnmatchedFinanceOptionsToDefault`, params);
	}

	postToggleShowSavingsValue(params) {
		return this.http.post(`${this.path}/togglesHowSavingsValue`, params);
	}

	postUpdateShowEnergySavingsSettings(params) {
		return this.http.post(`${this.path}/updateShowEnergySavingsSettings`, params);
	}

	postSaveEstimatedStartAndEndDate(params) {
		return this.http.post(`${this.path}/saveEstimatedStartAndEndDate`, params);
	}

	getEstimatedStartAndEndDate(params, cache = false) {
		return this.http.get(`${this.path}/estimatedStartAndEndDate`, {params, cache});
	}

	getEstimatedStartAndEndDateForCustomer(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/estimatedStartAndEndDateForCustomer`, {params, cache, headers});
	}

	postPresentConsultation(params) {
		return this.http.post(`${this.path}/presentConsultation`, params);
	}

	postRevertToDraft(params) {
		return this.http.post(`${this.path}/revertToDraft`, params);
	}

	postUpdateSystemNotes(params) {
		return this.http.post(`${this.path}/updateSystemNotes`, params);
	}

	postAcceptProposal(params) {
		return this.http.post(`${this.path}/acceptProposal`, params);
	}

	postCustomerAcceptProposal(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/customerAcceptProposal`, params, config);
	}

	getConsultationFromLegacyPresentedProposal(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/consultationFromLegacyPresentedProposal`, {params, cache, headers});
	}

	getConsultationFromLegacyPresentedProposalWithPosition(params, cache = false) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/consultationFromLegacyPresentedProposalWithPosition`, {params, cache, headers});
	}

	getConsultationFromLegacyAcceptedProposal(params) {
		const headers = {Auth: this.PUBLIC_KEY};
		return this.http.get(`${this.path}/consultationFromLegacyAcceptedProposal`, {params, headers});
	}

	postLogExpiredConsultationEvent(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/logExpiredConsultationEvent`, params, config);
	}

	postLogBlockedConsultationEvent(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/logBlockedConsultationEvent`, params, config);
	}

	postUnAcceptConsultationFromApp(params) {
		return this.http.post(`${this.path}/unacceptConsultationFromApp`, params);
	}

	/*
	 * @Deprecated - Upload base64 data not supported
	 */
	postAttachMediaToConsultationCheckList(params) {
		return this.http.post(`${this.path}/attachMediaToConsultationCheckList`, params);
	}

	postAddSurveyItemAttachment(params) {
		return this.http.post(`${this.path}/addSurveyItemAttachment`, params);
	}

	postRemoveMediaFromConsultationChecklist(params) {
		return this.http.post(`${this.path}/removeMediaFromConsultationChecklist`, params);
	}

	postUpdateDefaultEquipment(params) {
		return this.http.post(`${this.path}/updateDefaultEquipment`, params);
	}

	getRevenuesReportInAPeriodOfTime(params, cache = false) {
		return this.http.get(`${this.path}/revenuesReportInAPeriodOfTime`, {params, cache});
	}

	postAddJob(params) {
		return this.http.post(`${this.path}/addJob`, params);
	}

	postRecommendJob(params) {
		return this.http.post(`${this.path}/recommendJob`, params);
	}

	postArchiveConsultation(params) {
		return this.http.post(`${this.path}/archiveConsultation`, params);
	}

	postUnarchiveConsultation(params) {
		return this.http.post(`${this.path}/unarchiveConsultation`, params);
	}

	getAllConsultationStatuses(params, cache = false) {
		return this.http.get(`${this.path}/allConsultationStatuses`, {params, cache});
	}

	postLogViewedLostEvent(params) {
		const config = {headers: {Auth: this.PUBLIC_KEY}};
		return this.http.post(`${this.path}/logViewedLostEvent`, params, config);
	}

	getAllViewByDateOptions(params, cache = false) {
		return this.http.get(`${this.path}/viewByDateFilterOptionsByConsultationStatusesFilter`, {params, cache});
	}

	postMarkAsLost(params) {
		return this.http.post(`${this.path}/markAsLost`, params);
	}

	postRevertLostStatus(params) {
		return this.http.post(`${this.path}/revertLostStatus`, params);
	}

	getLostStatusReasons(params, cache = false) {
		return this.http.get(`${this.path}/lostStatusReasons`, {params, cache});
	}

	getDetails(params, cache = false) {
		return this.http.get(`${this.path}/details`, {params, cache});
	}

	getSideNotes(params, cache = false) {
		return this.http.get(`${this.path}/notes`, {params, cache});
	}

	postAddSideNote(params) {
		return this.http.post(`${this.path}/addNote`, params);
	}

	postEditSideNote(params) {
		return this.http.post(`${this.path}/editNote`, params);
	}

	postRemoveSideNote(params) {
		return this.http.post(`${this.path}/removeNote`, params);
	}

	postToggleShowNoteOnInspector(params) {
		return this.http.post(`${this.path}/togglesHowNoteOnInspector`, params);
	}

	getConsultationExpirationDate(params, cache = false) {
		return this.http.get(`${this.path}/consultationExpirationDate`, {params, cache});
	}

	postUpdateConsultationExpirationDate(params) {
		return this.http.post(`${this.path}/updateConsultationExpirationDate`, params);
	}

	postResetConsultationExpirationDate(params) {
		return this.http.post(`${this.path}/resetConsultationExpirationDate`, params);
	}
}

let compMod = angular.module('mod.svc.consultationService', []).service('ConsultationService', Consultation);
export default compMod = compMod.name;
