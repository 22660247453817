export class ProviderSettingsService {
	constructor($http, API, $q) {
		'ngInject';
		this.q = $q;
		this.http = $http;
		this.path = `${API}/v1/setting`;
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {boolean} [doCache=false]
	 * @returns {Promise} Promise object
	 */
	getGlobalExpirationDate(params) {
		return this.http.get(`${this.path}/globalExpirationDateForConsultations`, params);
	}

	/**
	 * @param {Object} params
	 * @param {number} params.serviceProviderId
	 * @param {number} params.userId
	 * @returns {Promise} Promise object
	 */
	postUpdateGlobalExpiration(params) {
		return this.http.post(`${this.path}/updateConsultationExpirationIntervalInDays`, params);
	}
}

let compMod = angular
	.module('mod.svc.ProviderSettingsService', [])
	.service('ProviderSettingsService', ProviderSettingsService);
export default compMod = compMod.name;
