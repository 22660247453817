export const OfficeCustomEquipmentListState = {
	url: '/custom-equipment',
	template: '<custom-equipment-page />',
	title: 'List Custom Equipment',
	resolve: {
		/** @ngInject */
		loadComponent: ($q, $ocLazyLoad) => {
			const _d = $q.defer();
			import(
				/* webpackChunkName: "officeCustomEquipPageMod" */
				'./office-custom-equipment-page.component.js'
			)
				.then(res => {
					$ocLazyLoad.load({name: res.default});
					_d.resolve(res);
				})
				.catch(err => _d.reject(err));
			return _d.promise;
		},
	},
};

export const OfficeCustomEquipmentUpdateRoute = {
	url: '/custom-equipment-update/:series_id',
	template: '<custom-equipment-update-page />',
	params: {
		series_id: null,
		label: null,
	},
	resolve: {
		/** @ngInject */
		loadComponent: ($q, $ocLazyLoad) => {
			const _d = $q.defer();
			import(
				/* webpackChunkName: "officeCustomEquipUpdatePageMod" */
				'./office-custom-equipment-update.component.js'
			)
				.then(res => {
					$ocLazyLoad.load({name: res.default});
					_d.resolve(res);
				})
				.catch(err => _d.reject(err));
			return _d.promise;
		},
	},
};

export const OfficeCustomEquipmentCreateRoute = {
	url: '/custom-equipment-create/:component_id/:component_label',
	template: '<custom-equipment-create-page />',
	params: {
		component_id: null,
		component_label: null,
		component_capacities: null,
	},
	resolve: {
		/** @ngInject */
		loadComponent: ($q, $ocLazyLoad) => {
			const _d = $q.defer();
			import(
				/* webpackChunkName: "officeCustomEquipCreatePageMod" */
				'./office-custom-equipment-create.component.js'
			)
				.then(res => {
					$ocLazyLoad.load({name: res.default});
					_d.resolve(res);
				})
				.catch(err => _d.reject(err));
			return _d.promise;
		},
	},
};
