import './service-unavailable.scss';
import ServiceUnavailableTpl from './service-unavailable.html';
//
const compConfig = {
	template: ServiceUnavailableTpl,
	controller: serviceUnavailableController,
	bindings: {
		modalInstance: '<',
		resolve: '<',
	},
};
/* @ngInject */
function serviceUnavailableController(OCA_NUMBER) {
	const $ctrl = this;
	$ctrl.$onInit = () => {
		$ctrl.title = 'Sorry, we are down for maintenance';
		$ctrl.message = 'We will be back up shortly. If you have an urgent request give us a call at ';
		$ctrl.phone = OCA_NUMBER;
	};
}
// service-unavailable
let compMod = angular.module('mod.cmp.serviceUnavailable', []).component('serviceUnavailable', compConfig);
export default compMod = compMod.name;
