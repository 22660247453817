export class HttpBuffer {
	constructor($http) {
		'ngInject';
		this.$http = $http;
		this.buffer = [];
	}

	retryHttpRequest(config, deferred) {
		// Deferred -> Object.resolve, Object.reject
		// Promise -> Object.then, Object.catch
		const successCallback = response => deferred.resolve(response);
		const errorCallback = response => deferred.reject(response);
		this.$http(config).then(successCallback).catch(errorCallback);
	}

	/**
	 * Appends HTTP request configuration object with deferred response attached to buffer.
	 * @return {Array} The new length of the buffer.
	 */
	append(config, deferred) {
		return this.buffer.push({config, deferred});
	}

	/**
	 * Abandon or reject (if reason provided) all the buffered requests.
	 * @param  {String} reason - message to send as reject
	 */
	rejectAll(reason) {
		if (reason) {
			for (let i = 0; i < this.buffer.length; ++i) {
				this.buffer[i].deferred.reject(reason);
			}
		}
		this.buffer = [];
	}

	/**
	 * Retries all the buffered requests clears the buffer.
	 * @param  {Function} updater
	 */
	retryAll(updater) {
		for (let i = 0; i < this.buffer.length; ++i) {
			const config = updater(this.buffer[i].config);
			const deferred = this.buffer[i].deferred;
			if (config !== false && deferred) this.retryHttpRequest(config, deferred);
		}
		this.buffer = [];
	}

	clearBuffer(reason) {
		for (let i = 0; i < this.buffer.length; ++i) {
			this.buffer[i].deferred.resolve(reason);
		}
		this.buffer = [];
	}

	/**
	 * Find is request was already buffered
	 * @param  {Object} http config - config of http request
	 * @return {Boolean} - boolean
	 */
	findOne(config) {
		const _filtered = this.buffer.filter(item => item.config.url === config.url);
		return _filtered.length > 0;
	}
}
export default HttpBuffer;
// based on https://github.com/witoldsz/angular-http-auth
