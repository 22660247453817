/**
 * @description manage features flags
 **/
export class FeaturesFlags {
	constructor($q, $log, StorageService) {
		'ngInject';
		this.$q = $q;
		this.$log = $log;
		this.storeKey = 'feature_flags';
		this.StorageService = StorageService;
	}

	/**
	 * @description add flags to storage
	 * @param {Object} flagObj
	 * @returns Boolean
	 */
	addFlags(flagObj) {
		if (!this.isObject(flagObj) && Object.keys(flagObj).length === 0) {
			this.$log.error('Empty object not valid, "flagObj" has to have at lease one property');
			return false;
		}

		const currentFlags = this.getAllFlags() || {};
		const allFlags = Object.assign({}, currentFlags, flagObj);
		if (!this.StorageService.set(this.storeKey, allFlags)) {
			this.$log.error('Unable to store all flags');
			return false;
		}
		return this.getAllFlags();
	}

	getFlag(flag) {
		if (!flag) {
			this.$log.error('Invalid key, expecting an string key');
			return false;
		}

		const allFlags = this.getAllFlags();
		return allFlags?.hasOwnProperty(flag) ? allFlags[flag] : null;
	}

	getFlags(flags = []) {
		if (!Array.isArray(flags)) {
			this.$log.error('Non array not valid, expecting an array of flags keys');
			return null;
		}

		const flagsObj = {};
		const allFlags = this.getAllFlags();
		Object.keys(allFlags).forEach(k => {
			if (flags.includes(k)) flagsObj[k] = allFlags[k];
		});
		return flagsObj;
	}

	getAllFlags() {
		if (arguments.length > 0) {
			this.$log.error('Param not expected');
			return null;
		}

		return this.StorageService.get(this.storeKey);
	}

	isObject(param) {
		return typeof param === 'object' && param !== null && !Array.isArray(param);
	}
}
//
let compMod = angular.module('mod.svc.FeaturesFlags', []).service('FeatureFlagsSvc', FeaturesFlags);
export default compMod = compMod.name;
